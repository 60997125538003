/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { actions } from "./actions";
import { wrap } from "@/modules/comlink";
import {
  call,
  select,
  put,
  getContext,
  delay,
  all,
  race,
  take,
} from "typed-redux-saga";
import isEqual from "lodash/fp/isEqual";
import { selectStartAndEnd, selectIsRollingMode } from "@/store/selectors";
import { fetchAllJobMetaData } from "@/store/sagas/shared";
import { getGlobalConfigs } from "@/index";
import { CMSClientType } from "@/cms-api";
import { Exposed } from "./index.worker";
import { setNotification } from "@/store/global/thunks";
import { notificationTypes } from "@centralwebteam/jellyfish";
import { ISOString, JobSummary } from "@centralwebteam/narwhal";
import { rootActions, RootState } from "@/store";
import { toUTCISOString } from "@/modules/dateFormats/index";
import { uniqWith } from "lodash/fp";
import { JobPresentation } from "@/presentation/Job";
export const getStaticData = function* () {
  try {
    const [from, to] = yield* select(selectStartAndEnd);
    yield* all([call(calculateJobPerformanceSaga, from, to, "static")]);
  } catch (error) {
    console.log(error);
  }
};

export function* liveRefreshWatcher() {
  const config = yield* call(() => getGlobalConfigs());
  yield* put(
    rootActions.machineAnalysis.liveRefreshCompleted({
      date: new Date().toISOString(),
    })
  );
  while (true) {
    yield* all([call(waitForDateMode, "rolling")]);
    yield* race({
      cancel: race([
        call(waitForLiveRefreshModeChanged),
        call(waitForDateMode, "static"),
      ]),
      fetchDelta: call(fetchDeltaSupervisor, config.refreshRatePerformanceInMS),
    });
  }
}

/**
 * Terminates when date mode matches params.mode.
 */
function* waitForDateMode(mode: "static" | "rolling") {
  do {
    const isRollingMode: boolean = yield* select(selectIsRollingMode);
    if (isRollingMode === (mode === "rolling")) return;
  } while (yield* take(rootActions.session.startEndUpdated));
}

function* waitForLiveRefreshModeChanged() {
  const isLiveRefreshModeOn = yield* select(
    (state: RootState) => state.global.isLiveRefreshModeOn
  );
  do {
    if (!isLiveRefreshModeOn) {
      return;
    }
  } while (yield* take(rootActions.global.liveRefreshModeStateChanged));
}

/**
 * Controls when to call fetchDeltas saga
 */
function* fetchDeltaSupervisor(refreshRate: number) {
  while (true) {
    yield* delay(refreshRate);
    yield* call(fetchDeltas);
  }
}

function* fetchDeltas() {
  const now = toUTCISOString(new Date());
  try {
    const {
      jobPerformance: { lastFetchedRange },
    } = yield* select();
    const isRollingMode = yield* select(selectIsRollingMode);
    const isLiveRefreshModeOn = yield* select(
      (state) => state.global.isLiveRefreshModeOn
    );
    if (!isRollingMode || !isLiveRefreshModeOn || !lastFetchedRange) {
      return;
    }
    yield* put(
      rootActions.machineAnalysis.liveRefreshCompleted({
        date: new Date().toISOString(),
      })
    );
    const lastFetched = lastFetchedRange[1];
    yield* call(calculateJobPerformanceSaga, lastFetched, now, "delta");
  } catch (error) {
    console.log(error);
  }
}

/** Fetch jobSummariesExtended data page by page and map to a flat array of JobPresentations.
 * @param from start date
 * @param to end date
 */
function* fetchJobSummariesExtended(
  from: ISOString,
  to: ISOString,
  type: "static" | "delta"
) {
  const config = yield* call(getGlobalConfigs);
  const client: CMSClientType = yield getContext("client");
  const take = config.pagedTake;
  const totalTake = config.maxTake;
  const fetchCountList = getFetchCountList(take, totalTake);
  let jobSummariesExtendedData = [] as JobSummary[];
  if (type === "static") {
    /** have we got all the summaries yet? */
    let finished = false;

    for (const key of fetchCountList) {
      if (finished) break;
      const skip = key * take;
      const dt = yield* call(
        (req) => client.jobs.summaryExtended(req).promise,
        {
          query: {
            from,
            to,
            take,
            skip,
          },
        }
      );
      if (dt) {
        const mappy = Object.values(dt).filter((m) => m);
        if (!mappy.some((m) => m && m.length >= take)) finished = true;
        // concat is faster and safer for large arrays; using a spread can cause a stack overflow
        jobSummariesExtendedData = jobSummariesExtendedData.concat(
          mappy.flat()
        );
      }
    }
  } else {
    const dt = yield* call((req) => client.jobs.summaryExtended(req).promise, {
      query: {
        from,
        to,
        take,
      },
    });
    if (dt)
      jobSummariesExtendedData = Object.values(dt)
        .filter((m) => m)
        .flat();
  }

  return jobSummariesExtendedData.map((p: any) => {
    return {
      ...p,
      verdict: p.verdict === "NoVerdict" ? "No Verdict" : p.verdict,
      status: p.status === "NoJobEnd" ? "No Job End" : p.status,
    };
  }) as JobPresentation[];
}

/**
 * Fetches job summaries from the specified time period, dispatching actions to add them to the store.
 * Live refresh fetches jobs or extended job summaries at specifica inverval, both measuring machine or any other metadata
 */
export function* calculateJobPerformanceSaga(
  from: string,
  to: string,
  type: "static" | "delta"
) {
  const worker = new Worker("./index.worker.ts", { type: "module" });
  try {
    const config = yield* call(() => getGlobalConfigs());

    const client: CMSClientType = yield getContext("client");
    const proxy = wrap<Exposed>(worker);

    const {
      jobPerformance: { lastFetchedRange, activeMetadata },
    } = yield* select();

    if (lastFetchedRange && isEqual(lastFetchedRange, [from, to])) {
      return;
    }
    yield* put(actions.fetchingData(type));
    let jobSummariesOrExtendedSummaries = [] as JobPresentation[];

    const previousMetadataNamesList = yield* select(
      (s) => s.jobPerformance.metadataNamesList
    );
    const metadataList = yield* fetchUniqueMetaDataNames({
      startDate: from,
      endDate: to,
    });
    const allUniqueMetaDataNames = [
      ...new Set(previousMetadataNamesList.concat(metadataList)),
    ];
    yield* put(actions.metadataLoaded(allUniqueMetaDataNames as string[]));

    const extendedSummaryFetched = yield* select(
      (state: RootState) => state.jobPerformance.extendedSummaryFetched
    );

    try {
      // avoid making summaries endpoint call when extended summaries call is already made (selected metadata from the dropdown)
      if (
        (activeMetadata === "Measuring Machine" ||
          (allUniqueMetaDataNames &&
            !allUniqueMetaDataNames?.includes(activeMetadata))) &&
        !extendedSummaryFetched
      ) {
        jobSummariesOrExtendedSummaries = yield* call(
          (req) => client.jobs.summary(req).promise,
          {
            query: {
              from,
              to,
              take: config.maxTake,
            },
          }
        );
      } else {
        jobSummariesOrExtendedSummaries = yield* fetchJobSummariesExtended(
          from,
          to,
          type
        );
      }
    } catch (error) {
      // @ts-ignore
      yield* put(setNotification("", [error.message], notificationTypes.error));
    }

    yield* put(actions.workingOnData());

    if (jobSummariesOrExtendedSummaries.length >= config.warningLimit) {
      yield* put(actions.jobSummariesDataWarningLimitBreached());
    }

    if (type === "delta") {
      const oldJobSummaries = yield* select(
        (state) => state.jobPerformance.jobSummaries
      );
      jobSummariesOrExtendedSummaries = [
        ...jobSummariesOrExtendedSummaries,
        ...oldJobSummaries,
      ];
      // jobSummaries can have duplicate after merge, get the unique jobSummaries
      jobSummariesOrExtendedSummaries = uniqWith(
        (a: JobPresentation, b: JobPresentation) =>
          a.id === b.id && a.machineId === b.machineId && a.name === b.name,
        jobSummariesOrExtendedSummaries
      );
      jobSummariesOrExtendedSummaries = jobSummariesOrExtendedSummaries.sort(
        (a, b) => Date.parse(b.start) - Date.parse(a.start)
      );
    }

    const {
      jobTypes,
      jobTypePerformances,
      machinePerformances,
      metadataPerformances,
    } = yield* call(
      proxy.processJobs,
      jobSummariesOrExtendedSummaries,
      activeMetadata
    );

    yield* put(
      actions.jobsLoaded({
        jobTypes,
        jobSummaries: jobSummariesOrExtendedSummaries,
      })
    );

    yield* put(
      actions.jobTypePerformanceCalculated({
        performance: jobTypePerformances,
      })
    );

    if (activeMetadata === "Measuring Machine") {
      yield* put(
        actions.machinePerformanceCalculated({
          performance: machinePerformances,
        })
      );
    } else
      yield* put(
        actions.metadataPerformanceCalculated({
          performance: metadataPerformances,
        })
      );

    if (!jobSummariesOrExtendedSummaries.length) {
      yield* delay(2000);
    }
    yield* put(actions.workCompleted({ lastFetchedRange: [from, to] }));
  } finally {
    worker.terminate();
  }
}

// gets called when measuring machine or any other metadata selected from dropdown
export function* calculateJobPerformanceOnMetadataChange() {
  const worker = new Worker("./index.worker.ts", { type: "module" });
  const proxy = wrap<Exposed>(worker);
  const [from, to] = yield* select(selectStartAndEnd);

  let jobSummariesExtended = yield* select(
    (state: RootState) => state.jobPerformance.jobSummaries
  );
  const activeMetadata = yield* select(
    (state: RootState) => state.jobPerformance.activeMetadata
  );
  const extendedSummaryFetched = yield* select(
    (state: RootState) => state.jobPerformance.extendedSummaryFetched
  );

  if (activeMetadata !== "Measuring Machine" && !extendedSummaryFetched) {
    yield* put(actions.fetchingData("static"));

    jobSummariesExtended = yield* fetchJobSummariesExtended(from, to, "static");

    yield* put(actions.workingOnData());

    const {
      jobTypes,
      jobTypePerformances,
      machinePerformances,
      metadataPerformances,
    } = yield* call(proxy.processJobs, jobSummariesExtended, activeMetadata);

    yield* put(actions.extendedSummaryFetched());
    yield* put(
      actions.jobsLoaded({
        jobTypes,
        jobSummaries: jobSummariesExtended,
      })
    );

    yield* put(
      actions.jobTypePerformanceCalculated({
        performance: jobTypePerformances,
      })
    );

    if (activeMetadata === "Measuring Machine") {
      yield* put(
        actions.machinePerformanceCalculated({
          performance: machinePerformances,
        })
      );
    } else {
      yield* put(
        actions.metadataPerformanceCalculated({
          performance: metadataPerformances,
        })
      );
    }
    yield* put(actions.workCompleted({ lastFetchedRange: [from, to] }));
  } else {
    yield* put(actions.workingOnData());
    const {
      jobTypes,
      jobTypePerformances,
      machinePerformances,
      metadataPerformances,
    } = yield* call(proxy.processJobs, jobSummariesExtended, activeMetadata);

    yield* put(actions.extendedSummaryFetched());
    yield* put(
      actions.jobsLoaded({
        jobTypes,
        jobSummaries: jobSummariesExtended,
      })
    );

    yield* put(
      actions.jobTypePerformanceCalculated({
        performance: jobTypePerformances,
      })
    );

    if (activeMetadata === "Measuring Machine") {
      yield* put(
        actions.machinePerformanceCalculated({
          performance: machinePerformances,
        })
      );
    } else {
      yield* put(
        actions.metadataPerformanceCalculated({
          performance: metadataPerformances,
        })
      );
    }
    yield* put(actions.workCompleted({ lastFetchedRange: [from, to] }));
  }
}

function* fetchUniqueMetaDataNames({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  try {
    const JobMetadata = yield* fetchAllJobMetaData({ startDate, endDate });
    const metadataNames = JobMetadata?.filter(
      (p: any) => p.displayHints?.userVisible
    )?.map((item: any) => item.name);
    return [...new Set(metadataNames)];
  } catch (error) {
    console.error(error);
  }
}

function getFetchCountList(take: number, totalTake: number) {
  const fetchCount = Math.ceil(totalTake / take);
  return Array(fetchCount)
    .fill(null)
    .map((_, i) => i);
}
