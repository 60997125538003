/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
// #region imports
import { produce, enableMapSet } from "immer";
import { Reducer } from "redux";
import { getType } from "typesafe-actions";
import i18n from "@/i18n";
import {
  SystemPermissions,
  MachineState,
  UPTIME_STATES,
  UnitDisplayHint,
} from "@centralwebteam/narwhal";
import {
  Routes,
  mapRouteNameToSystemPermissions,
  routes,
  mapRouteNameToPath,
} from "./session/routeDefinitions";
import { RootActions, rootActions } from "./rootActions";
import {
  getValidStartAndEndValues,
  isValidStartToken,
  selectActiveMeasurementTypeIds,
} from "./selectors";
import { addMinutes, max } from "date-fns";
import {
  MeasurementCharacteristicPresentation,
  createUniqueMeasurementID,
} from "@/presentation/MeasurementCharacteristic";
import { toggleArrayItem } from "@/modules/toggleArrayItem";
import { getPopup } from "@/store/global/selectors";
import { orderBy, findLast, sortedUniqBy, isEqual } from "lodash/fp";
import { AlertLevel } from "@/presentation/Alert/level";
import {
  State,
  JobAggregate,
  Popup,
  ManageMachineProvisioningState,
  MachinePerformanceState,
  CurrentStatusState,
  JobPerformanceState,
  ManageAssetsState,
  Filter,
  FilterState,
  GlobalState,
  ManageUsersState,
  knownStartValues,
  ManageClientsState,
  ProcessUpdatesState,
  ManageNotificationsState,
  MeasurementTypeDetail,
  ManageConnectorsState,
  additiveViews,
} from "./state";
import { notUndefined } from "@/@types/guards/notUndefined";
import { getContrastingColour } from "../modules/colours/colours";
import { RootState } from ".";
import { getStateColour } from "@/presentation/colours";
import { last24Hours, nowISOString } from "@/modules/dateFormats";
import {
  setMachineAnalysisPageTab,
  getMachineAnalysisPageTab,
} from "./appLocalStorageStates";
import { getRouteFromPreferences } from "@/modules/preferences";
import { getAncestors } from "@/presentation/Location";
import { getRowId, matchesJob } from "@/handlers/JobPerformance/functions";
// #endregion

// The enableMapSet() function allows to use Map and Set objects in Redux state.
// By default, immer does not support these objects, because they are not serializable and may cause issues with some Redux features.
enableMapSet();
const maxTake = 30000;

let performanceNotificationHeader = "",
  performanceNotificationBody: string[] = [],
  settingsNotificationHeader = "";
i18n.then((t) => {
  performanceNotificationHeader = t("message-Performance Warning");
  performanceNotificationBody = [
    t("message-largeData"),
    t("message-reduceTimeRange"),
  ];
  settingsNotificationHeader = t("message-SettingsUpdated");
});

const getPathFromAvailableRoutes = (pages: string[], pathname: string) => {
  const allowedRoute =
    pages === undefined ||
    // handle invalid pages data, should have a string array as pages
    (pages !== undefined &&
      pages.filter((f) => typeof f !== "string").length > 0) ||
    (pages?.length !== 0 &&
      pages?.some(
        (page: string) =>
          page.toLowerCase() ===
          pathname.replace("-", " ").replace(/[^a-zA-Z' ']/g, "")
      ));
  return (pathname === "/current-status" && allowedRoute) ||
    (pathname === "/" &&
      pages?.length !== 0 &&
      pages?.some((page: string) => page.toLowerCase() === "current status"))
    ? "/current-status"
    : (pathname === "/job-performance" && allowedRoute) ||
        (pathname === "/" &&
          pages?.length !== 0 &&
          pages?.some(
            (page: string) => page.toLowerCase() === "job performance"
          ))
      ? "/job-performance"
      : (pathname === "/machine-performance" && allowedRoute) ||
          (pathname === "/" &&
            pages?.length !== 0 &&
            pages?.some(
              (page: string) => page.toLowerCase() === "machine performance"
            ))
        ? "/machine-performance"
        : pathname !== "/current-status" &&
            pathname !== "/job-performance" &&
            pathname !== "/machine-performance" &&
            routes.find((route) => mapRouteNameToPath[route].match(pathname))
          ? pathname
          : "manage/myaccount";
};
// #region state transformation functions
export const userHasPermissionToRoute = (
  route: Routes,
  permissions: SystemPermissions[] | undefined
) =>
  mapRouteNameToSystemPermissions[route].length === 0 ||
  (permissions === undefined &&
    mapRouteNameToSystemPermissions[route].length === 0) ||
  (permissions !== undefined &&
    mapRouteNameToSystemPermissions[route].some((permission) =>
      permissions.includes(permission)
    ));

function addAndRemoveEqually<T extends { id: string }>(
  collection: Mappy<T>,
  additions: T[],
  limit: number
) {
  const keys = Object.keys(collection);
  const limitReached = keys.length + additions.length > limit;
  additions.forEach((item, index) => {
    collection[item.id] = item;
    if (limitReached) delete collection[keys[index]];
  });
}

const removePopup = (id: string, popups: Popup[]) => {
  const index = popups.findIndex((p) => p.id === id);
  if (index > -1) popups.splice(index, 1);
};

function removeUndefinedValues(obj: any): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const newObj: any = {};

  Object.keys(obj).forEach((key) => {
    const value = removeUndefinedValues(obj[key]);
    if (value !== undefined) {
      if (typeof value === "object" && Object.keys(value).length === 0) {
        // skip adding empty object
        return;
      }
      newObj[key] = value;
    }
  });

  if (Object.keys(newObj).length === 0) {
    return undefined;
  }

  return newObj;
}

const updateExistingJobAggregateRecord = (
  delta: JobAggregate,
  old: JobAggregate
): JobAggregate => {
  return {
    completed: delta.completed + (old.completed ?? 0),
    incomplete: delta.incomplete + (old.incomplete ?? 0),
    fail: delta.fail + (old.fail ?? 0),
    noVerdict: delta.noVerdict + (old.noVerdict ?? 0),
    passAndWarn: delta.passAndWarn + (old.passAndWarn ?? 0),
    total: delta.total + (old.total ?? 0),
  };
};

const calculateMachineStatuses = (statuses: [MachineState, number][]) => {
  const runningTime = statuses
    .filter(([status]) => UPTIME_STATES.includes(status))
    .reduce(
      (totalSeconds, statusSummary) => totalSeconds + statusSummary[1],
      0
    );
  const totalTime = statuses.reduce(
    (totalSeconds, statusSummary) => totalSeconds + statusSummary[1],
    0
  );

  const sortedCalculatedStatuses = statuses
    .map((item) => {
      return {
        name: item[0],
        percentage: (item[1] / totalTime) * 100,
        fill: getStateColour(item[0]),
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  return {
    runningTime,
    percent: (runningTime / totalTime) * 100,
    machineStatuses: sortedCalculatedStatuses,
  };
};

// Generates new active values for each characteristic so that only the
// characteristics which exist in the job remain active.
function calculateCharacteristicActiveValues(
  jobCharacteristics: MeasurementCharacteristicPresentation[] | undefined,
  measurementDetails: Mappy<MeasurementTypeDetail>
): Mappy<MeasurementTypeDetail> {
  const updatedMeasurementDetails: Mappy<MeasurementTypeDetail> = {};

  const jobMeasurementKeys = (jobCharacteristics ?? []).map(
    createUniqueMeasurementID
  );

  Object.keys(measurementDetails).forEach((detailsKey: string) => {
    updatedMeasurementDetails[detailsKey] = {
      ...measurementDetails[detailsKey]!,
      active: jobMeasurementKeys?.some((jobKey) => jobKey === detailsKey)
        ? measurementDetails[detailsKey]!.active
        : false,
    };
  });

  return updatedMeasurementDetails;
}

//#endregion

// #region default constants
const defaultSelectedAlertLevels = [
  AlertLevel.error,
  AlertLevel.info,
  AlertLevel.warning,
  AlertLevel.mastering,
  AlertLevel.offset,
  AlertLevel.offsetError,
  AlertLevel.offsetWarning,
  AlertLevel.offsetInfo,
  AlertLevel.offsetOther,
  AlertLevel.offsetOtherInfo,
];

const defaultTransform = {
  k: 1,
  x: 0,
  y: 0,
};

const popupDefaultZIndex = 9;

const protectedManageRoutes: Readonly<Routes[]> = [
  "manage myaccount",
  "manage mysettings",
  "manage myconnectors",
  "manage assets",
  "manage provisioning",
  "manage users",
];

const emptyMachinePerformance = () =>
  ({
    totalErrorAlerts: 0,
    statusOk: { seconds: 0, percent: 0 },
  }) as const;

const emptyJobAggregate = (): JobAggregate => {
  return {
    completed: 0,
    incomplete: 0,
    fail: 0,
    passAndWarn: 0,
    noVerdict: 0,
    total: 0,
  };
};
// #endregion

// #region partial initial state functions
export const initialMachinePerformanceState = (): MachinePerformanceState => ({
  machineJobAggregates: {},
  machinePerformances: {},
  lastFetched: null,
  work: "Idle",
  sortColumn: "Fails",
  sortDirection: "desc",
  openMachines: [],
  machineJobStats: {},
  machineStatuses: {},
  machineAlerts: {},
});

export const initialFilterState = (): Filter => ({
  locationId: [],
  machineLicensed: [],
  machineType: [],
  machineStatus: [],
  name: [],
  userEmail: [],
  verdict: [],
  clientName: [],
  alertLevel: defaultSelectedAlertLevels,
});
export const filterInitialState = (): FilterState => ({
  draft: initialFilterState(),
  active: initialFilterState(),
});

export const globalInitialState = (): GlobalState => ({
  machines: [],
  machineTypes: [],
  locations: [],
  expandedLocations: [],
  notification: {
    show: false,
    body: [],
    header: "",
    id: "",
    type: "error",
  },
  dialogue: {
    show: false,
    message: "",
    confirm: () => {
      void 0;
    },
  },
  popups: [],
  lastFetched: null,
  expandedMachines: [],
  activeTab: "Info",
  preferences: {
    pages: undefined,
  },
  defaultRoute: "current status",
  files: {
    filesDataForFocusedJob: [],
    filesDetailsForFocusedJob: [],
    downloadState: "initial",
  },
  isLiveRefreshModeOn: true,
  jobSelectedManuallyFromJobsTimeLine: false,
});

export const manageMachineProvisioningInitialState =
  (): ManageMachineProvisioningState => ({
    machines: [],
    selectedRegistrationId: "",
  });

export const focusedMachineInitialState = (): State["focusedMachine"] => ({
  lastFetched: undefined,
  work: {
    data: "Idle",
    focusedJobMoreDetails: "Idle",
    timeSeriesValues: "Idle",
    measurementSeriesValues: "Idle",
  },
  jobs: {},
  states: {},
  alerts: {},
  processActions: {},
  hints: [],
  awaitingHints: false,
  mainMeasurementDetail: undefined,
  measurementDetails: {},
  measurementValues: {},
  timeSeriesLimits: {},
  timeSeriesDetails: {},
  timeSeriesValues: {},
  selectedAlerts: [],
  alertTypeParetoSelectedReason: undefined,
  measurementPlotType: "actual",
  stateSummary: undefined,
  filters: {
    alertName: [],
    jobType: [],
  },
});

export const focusedJobInitialState = (): State["focusedJob"] => ({
  details: undefined,
  relatedJobs: {},
  moreDetails: undefined,
  relatedFocusedJob: { sortColumn: undefined, sortDirection: "desc" },
  reducedEndDate: undefined,
  singleJobTable: {
    sortColumn: "Graphic",
    sortDirection: "desc",
  },
});

export const currentStatusInitialState = (): CurrentStatusState => ({
  machineAlerts: {},
  viewMode: "MICRO",
  machineJobSummaries: {},
  jobMeasurementCharacteristicsLoading: [],
  jobMeasurementCharacteristics: {},
  machineCarouselData: {},
  selectedMachineAlerts: undefined,
  cycleData: {},
  lastFetchedMachine: null,
  lastFetchedCarouselData: null,
  lastFetchedCycleTime: null,
  working: true,
});

export const jobPerformanceInitialState = (): JobPerformanceState => ({
  extendedSummaryFetched: false,
  metadataNamesList: [],
  jobTypes: [],
  jobSummaries: [],
  // job type name -> performance
  jobTypePerformances: {},
  // machine id -> job type name -> performance
  // metadataname -> job type name -> performance,
  metadataPerformance: {},
  machinePerformances: {},
  openJobs: [],
  openMachines: [],
  openMetadataValues: [],
  activeMetadata: "Measuring Machine",
  sortUnit: "Count",
  sortColumn: "Fails",
  sortDirection: "desc",
  detailSortColumn: "Job Start",
  detailSortDirection: "desc",
  machineJobAggregateSortColumn: "Machine",
  machineJobAggregateSortDirection: "desc",
  metadataJobAggregateSortColumn: "Metadata",
  metadataJobAggregateSortDirection: "desc",
  work: "Idle",
  lastFetchedRange: null,
});

export const manageAssetsInitialState = (): ManageAssetsState => ({
  singleSelectedNode: null,
  multiSelectedNodes: [],
  mode: "single",
  openSections: ["licences", "locations"],
  activeTab: "",
  confirmDeletion: false,
  deleteEntityType: "none",
  refreshInProgress: false,
  machineConfigurations: {},
  createMachine: false,
  formDataInvalidated: false,
  connectionStatus: "none",
  connectionStatusBody: "",
  controllerResponseFromMachine: {},
});

const manageConnectorsInitialState = (): ManageConnectorsState => ({
  connectorDetails: [],
});

export const manageUsersInitialState = (): ManageUsersState => ({
  users: [],
  selectedUserId: "",
  mode: "modify",
  confirmDeletion: false,
});

export const manageClientsInitialState = (): ManageClientsState => ({
  clients: [],
  selectedClientId: "",
  selectedClientSecret: undefined,
  mode: "modify",
  confirmDeletion: false,
});

export const manageNotificationsInitialState =
  (): ManageNotificationsState => ({
    notificationSupported: false,
    notificationIsActivated: false,
    openSections: [],
    toggledLocationIds: [],
    activeMachineAlerts: ["Critical", "Error"],
    activeMachineStatuses: ["Error"],
    activeJobStatuses: ["Aborted"],
    activeJobVerdicts: ["Fail"],
    activeLocations: [],
    activeMachines: [],
    notificationValues: [],
    toggleManageDevices: false,
    selectAllMachinesAndLocations: false,
    headingControllers: [],
  });
export const processUpdatesInitialState = (): ProcessUpdatesState => ({
  toolOffsetApplied: [],
  processUpdatesPerf: {},
  processUpdateDetails: {},
  processUpdateDetailsMore: {},
  processUpdatesPerfSortColumn: "Updated",
  processUpdatesPerfSortDirection: "desc",
  processUpdatesDetailsSortColumn: "Updated",
  processUpdatesDetailsSortDirection: "desc",
  detailsInnerTableSortColumn: "Updated",
  detailsInnerTableSortDirection: "desc",
  lastFetched: null,
  work: "Idle",
  openToolOffsets: [],
  openProcessUpdatesDetailsList: [],
  offsetAdjustmentSortColumn: "Updated",
  offsetAdjustmentSortDirection: "asc",
});
// #endregion

export const initialState = (): State => {
  return {
    manageServer: {
      serverLicenceState: "unknown",
      serverLicenceType: "unknown",
      serverLicenceDaysRemaining: "0",
      licenceErrorMessage: "",
      licenceRetryErrorMessage: "",
      showFooter: false,
    },
    machinePerformance: initialMachinePerformanceState(),
    manageUsers: manageUsersInitialState(),
    manageMyAccount: {
      activeTab: undefined,
    },
    manageProvisioning: manageMachineProvisioningInitialState(),
    manageAssets: manageAssetsInitialState(),
    manageClients: manageClientsInitialState(),
    manageNotifications: manageNotificationsInitialState(),
    manageSettings: {
      openSections: ["language setting"],
      languageSelected: localStorage?.getItem("i18nextLng") ?? "en",
    },
    manageConnectors: manageConnectorsInitialState(),
    jobPerformance: jobPerformanceInitialState(),
    filter: filterInitialState(),
    global: globalInitialState(),
    currentStatus: currentStatusInitialState(),
    processUpdates: processUpdatesInitialState(),
    startupState: "started",
    cacheLimit: maxTake,
    view: "manage myaccount",
    loginView: "sign-in",
    params: {
      start: knownStartValues[0],
      end: null,
      focusedMachineId: null,
      focusedJobId: null,
      token: null,
      umrt: null,
    },
    focusedJob: focusedJobInitialState(),
    latestJob: focusedJobInitialState(),
    focusedMachine: focusedMachineInitialState(),
    userViewPreference: {
      focusedOrLatestJobViewMeasurementType: "actual",
      focusedOrLatestJobViewType: "Single",
      machineAnalysisView: "Machine",
      activeSidebarSections: ["jobs"],
      sidebarFilters: {},
    },
    machineSelector: {
      selected: undefined,
      visibility: "collapsed",
    },
    aboutPopup: {
      visibility: "collapsed",
    },
    visualisations: {
      machineAnalysis: {
        dimensions: {
          timeline: {
            width: 1,
          },
          lineChart: {
            height: 1,
          },
        },
        transforms: {
          timeline: {
            k: 1,
            x: 0,
          },
          yAxes: {},
          yDeviation: {
            k: 1,
            y: 0,
          },
        },
        seriesColours: {},
      },
    },
    previousLiveModeToken: knownStartValues[0],
    username: "",
    authenticated: false,
    authenticating: false,
    locationEntityPermissions: [],
    systemPermissions: undefined as SystemPermissions[] | undefined,
    assignedLicences: [],
    unassignedLicences: [],
  };
};

export const reducer: Reducer<State, RootActions> = (
  state = initialState(),
  action
) =>
  produce(state, (draft) => {
    switch (action.type) {
      // #region ROUTE VIEW ACTIONS
      case getType(rootActions.session.routeChanged): {
        draft.manageAssets.connectionStatus = "none";
        draft.manageAssets.connectionStatusBody = "";
        let { pathname } = action.payload;

        // If no preferences are available (e.g. when first logged in), assume they really want the page they asked for
        if (draft.global.preferences.pages) {
          pathname = getPathFromAvailableRoutes(
            draft.global.preferences.pages,
            pathname
          );
        }
        const matchedRoute = routes.find((route) =>
          mapRouteNameToPath[route].match(pathname)
        );

        if (!matchedRoute) {
          console.warn(
            `No matched route for ${action.payload.pathname}, defaulting to ${state.global.defaultRoute}`
          );
          draft.view = state.global.defaultRoute;
        } else {
          switch (matchedRoute) {
            case "manage": {
              const redirectRoute = protectedManageRoutes.find((route) =>
                userHasPermissionToRoute(route, draft.systemPermissions || [])
              );
              draft.view = redirectRoute
                ? redirectRoute
                : state.global.defaultRoute;
              break;
            }
            case "manage assets":
            case "manage users":
            case "manage provisioning":
            case "manage mysettings":
            case "manage myconnectors":
            case "manage myaccount": {
              if (
                userHasPermissionToRoute(
                  matchedRoute,
                  draft.systemPermissions ?? []
                )
              ) {
                draft.view = matchedRoute;
              } else {
                const redirectRoute = protectedManageRoutes.find((route) =>
                  userHasPermissionToRoute(route, draft.systemPermissions || [])
                );
                draft.view = redirectRoute
                  ? redirectRoute
                  : state.global.defaultRoute;
              }
              break;
            }
            case "machine analysis": {
              draft.userViewPreference.machineAnalysisView =
                getMachineAnalysisPageTab();
              draft.view = matchedRoute;
              break;
            }
            case "reset password": {
              if (draft.authenticated) {
                draft.view = state.global.defaultRoute;
              } else {
                draft.view = "reset password";
              }
              break;
            }
            default: {
              draft.view = matchedRoute;
            }
          }
        }
        break;
      }
      // #endregion

      // #region ROUTING FROM OTHER ROUTES
      case getType(rootActions.global.currentStatusHeaderLinkClicked): {
        draft.view = "current status";
        break;
      }
      case getType(rootActions.global.machinePerformanceHeaderLinkClicked): {
        draft.view = "machine performance";
        break;
      }
      case getType(rootActions.global.jobPerformanceHeaderLinkClicked): {
        draft.view = "job performance";
        break;
      }
      case getType(rootActions.global.machineAnalysisHeaderLinkClicked): {
        setMachineAnalysisTab(draft);
        draft.view = "machine analysis";
        break;
      }
      case getType(rootActions.global.processUpdatesHeaderLinkClicked): {
        draft.view = "process updates";
        break;
      }
      case getType(rootActions.global.liveRefreshModeStateChanged): {
        draft.global.isLiveRefreshModeOn = action.payload.liveRefreshModeState;
        break;
      }
      case getType(rootActions.session.manageHeaderAssetsLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage assets",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage assets";
        break;
      }
      case getType(rootActions.session.manageHeaderMyAccountLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage myaccount",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage myaccount";
        break;
      }
      case getType(rootActions.session.manageHeaderMySettingsLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage mysettings",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage mysettings";
        break;
      }
      case getType(rootActions.session.manageHeaderMyConnectorsLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage myconnectors",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage myconnectors";
        break;
      }
      case getType(rootActions.session.manageHeaderUsersLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage users",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage users";
        break;
      }
      case getType(rootActions.session.manageHeaderProvisioningLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage provisioning",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage provisioning";
        break;
      }
      case getType(rootActions.session.manageHeaderClientsLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage clients",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage clients";
        break;
      }
      case getType(rootActions.session.manageHeaderNotificationsLinkClicked): {
        if (
          userHasPermissionToRoute(
            "manage notifications",
            draft.systemPermissions || []
          )
        )
          draft.view = "manage notifications";
        break;
      }
      case getType(rootActions.global.manageCogHeaderLinkClicked): {
        const redirectRoute = protectedManageRoutes.find((route) =>
          userHasPermissionToRoute(route, draft.systemPermissions || [])
        );
        draft.view = redirectRoute ? redirectRoute : state.global.defaultRoute;
        break;
      }
      case getType(rootActions.global.infoHeaderLinkEscapeKeyPressed): {
        draft.aboutPopup.visibility = "collapsed";
        break;
      }
      case getType(rootActions.session.resetPasswordReturnLinkClicked): {
        draft.view = state.global.defaultRoute;
        break;
      }
      case getType(
        rootActions.jobPerformance.focusedJobPopupMachineAnalysisButtonClicked
      ):
      case getType(
        rootActions.currentStatus.jobSummaryPopupMachineAnalysisButtonClicked
      ): {
        const {
          job: { id, start, end, machineId },
        } = action.payload;
        draft.view = "machine analysis";
        draft.params.focusedJobId = id;
        draft.params.start = addMinutes(new Date(start), -30).toISOString();
        if (end) {
          draft.params.end = max([
            addMinutes(new Date(end), 30),
            new Date(),
          ]).toISOString();
        } else {
          draft.params.end = null;
        }
        draft.global.jobSelectedManuallyFromJobsTimeLine = true;
        if (draft.params.focusedMachineId !== machineId) {
          draft.params.focusedMachineId = machineId;
          draft.focusedMachine = focusedMachineInitialState();
          draft.visualisations.machineAnalysis.seriesColours = {};
          draft.userViewPreference.sidebarFilters = {};
        }
        setMachineAnalysisTab(draft, true);
        break;
      }
      case getType(
        rootActions.processUpdates.detailsTableInnerTableRowClickedRedirect
      ): {
        const {
          job: { id, start, end, machineId },
          processUpdate: { time },
        } = action.payload;

        draft.view = "machine analysis";
        draft.params.focusedJobId = id;
        draft.params.start = addMinutes(new Date(start), -30).toISOString();
        draft.params.end = max([
          addMinutes(max([new Date(end || 0), new Date(time || 0)]), 30),
          new Date(),
        ]).toISOString();
        draft.global.jobSelectedManuallyFromJobsTimeLine = true;

        if (draft.params.focusedMachineId !== machineId) {
          draft.params.focusedMachineId = machineId;
          draft.focusedMachine = focusedMachineInitialState();
          draft.visualisations.machineAnalysis.seriesColours = {};
          draft.userViewPreference.sidebarFilters = {};
        }
        setMachineAnalysisTab(draft, true);
        break;
      }
      case getType(rootActions.machinePerformance.machineIconClicked):
      case getType(rootActions.jobPerformance.machineAggregateIconClicked):
      case getType(rootActions.currentStatus.currentStatusMachineNameClicked):
      case getType(rootActions.currentStatus.currentStatusMachineIconClicked): {
        draft.view = "machine analysis";
        draft.params.focusedJobId = null;
        draft.focusedJob = focusedJobInitialState();
        draft.latestJob = focusedJobInitialState();
        draft.params.focusedMachineId = action.payload.machineId;
        draft.focusedMachine = focusedMachineInitialState();
        draft.visualisations.machineAnalysis.seriesColours = {};
        draft.userViewPreference.sidebarFilters = {};
        setMachineAnalysisTab(draft, true);
        break;
      }
      case getType(rootActions.session.passwordResetSuccessfully): {
        draft.loginView = "sign-in";
        break;
      }
      case getType(rootActions.session.focusedJobNotFound): {
        draft.params.focusedJobId = null;
        break;
      }
      case getType(rootActions.session.focusedJobNotInVisibleTimerange): {
        draft.params.start = action.payload.job.start;
        draft.params.end = action.payload.job.end ?? new Date().toISOString();
        break;
      }
      case getType(rootActions.session.focusedJobForDifferentMachine): {
        draft.params.focusedMachineId = action.payload.job.machineId;
        draft.userViewPreference.sidebarFilters = {};
        break;
      }
      case getType(rootActions.session.forgotPasswordButtonClicked): {
        draft.view = "reset password";
        break;
      }
      // #endregion

      // #region  URL SYNC - ROUTE PARAMETERS
      case getType(rootActions.currentStatus.jobMarkerClicked):
      case getType(rootActions.currentStatus.latestJobMarkerClicked):
      case getType(rootActions.jobPerformance.jobRunClicked):
      case getType(
        rootActions.processUpdates.detailsTableInnerTableRowClickedSetJobDetails
      ): {
        if (draft.params.focusedJobId !== action.payload.job.id) {
          draft.focusedJob = focusedJobInitialState();
          draft.focusedJob.details = action.payload.job;
          draft.params.focusedJobId = action.payload.job.id;
        }
        setMachineAnalysisTab(draft, true);
        break;
      }
      case getType(rootActions.machineAnalysis.jobTimelineClicked): {
        draft.global.jobSelectedManuallyFromJobsTimeLine = true;
        draft.focusedMachine.work["focusedJobMoreDetails"] = "Fetching";
        draft.focusedJob = focusedJobInitialState();
        draft.params.focusedJobId = action.payload.id;
        draft.latestJob.details = undefined;

        break;
      }
      case getType(rootActions.machineAnalysis.jobSelectedManually): {
        draft.global.jobSelectedManuallyFromJobsTimeLine =
          action.payload.selected;
        break;
      }
      case getType(
        rootActions.machineAnalysis
          .setFocusedJobSingleJobTableHeadingColumnClicked
      ): {
        draft.focusedJob.singleJobTable.sortColumn = action.payload[0];
        draft.focusedJob.singleJobTable.sortDirection = action.payload[1];
        break;
      }
      case getType(rootActions.session.startEndUpdated): {
        const [start, end] = action.payload;
        const [validStart, validEnd] = getValidStartAndEndValues(start, end);
        // we want to set the previous start values so we can switch back
        if (isValidStartToken(validStart)) {
          draft.previousLiveModeToken = validStart;
        }
        draft.latestJob.details = undefined;
        draft.params.start = validStart;
        draft.params.end = validEnd;
        if (draft.view === "machine analysis") {
          draft.visualisations.machineAnalysis.transforms.timeline =
            defaultTransform;
        }
        break;
      }
      case getType(rootActions.session.umrtUpdated): {
        draft.params.umrt = action.payload;
        break;
      }
      case getType(rootActions.session.languageUpdated): {
        try {
          if (action.payload && localStorage?.setItem) {
            localStorage.setItem("i18nextLng", action.payload);
          }
        } catch (ex) {
          console.info("localStorage unavailable");
        }
        break;
      }
      // machine changes
      case getType(
        rootActions.machineAnalysis.machineSelectorSelectButtonClicked
      ):
      case getType(rootActions.session.focusedMachineIdUpdated): {
        if (action.payload !== draft.params.focusedMachineId) {
          draft.params.focusedJobId = null;
          draft.params.focusedMachineId = action.payload;
          draft.focusedMachine = focusedMachineInitialState();
          draft.visualisations.machineAnalysis.seriesColours = {};
          draft.userViewPreference.sidebarFilters = {};
          draft.latestJob.details = undefined;
          if (draft.focusedJob.details?.machineId !== action.payload) {
            draft.focusedJob = focusedJobInitialState();
            draft.userViewPreference.machineAnalysisView = "Machine";
            setMachineAnalysisPageTab(getMachineAnalysisPageTab() || "Machine");
          }
        }
        break;
      }
      case getType(rootActions.session.focusedJobIdUpdated): {
        if (action.payload !== draft.params.focusedJobId) {
          draft.params.focusedJobId = action.payload;
          draft.focusedJob = focusedJobInitialState();
        }
        draft.latestJob.details = undefined;
        break;
      }
      case getType(rootActions.session.passwordResetTokenUpdated): {
        draft.params.token = action.payload;
        break;
      }
      // #endregion

      // #region  USER AUTHENTICATION ACTIONS
      case getType(rootActions.session.userPasswordReset): {
        draft.view = "current status";
        draft.params.token = null;
        break;
      }
      case getType(rootActions.session.userPasswordFailedToReset): {
        draft.params.token = null;
        break;
      }
      case getType(rootActions.session.userIsLoggedOutAppStart): {
        return {
          ...initialState(),
          startupState: "complete",
          loginView: draft.loginView,
          manageServer: draft.manageServer,
          global: {
            ...globalInitialState(),
            notification: draft.global.notification,
          },
        };
      }
      case getType(rootActions.session.loginStateChanged): {
        draft.loginView = action.payload.state;
        break;
      }
      case getType(rootActions.session.userLoginSubmitted):
        draft.authenticating = true;
        break;
      case getType(rootActions.session.userLoggedIn): {
        draft.startupState = "complete";
        draft.authenticated = true;
        draft.authenticating = false;
        draft.manageServer.serverLicenceState = "licensed";
        draft.systemPermissions = action.payload.permissions;
        if (action.payload.username) draft.username = action.payload.username;
        break;
      }
      case getType(rootActions.session.userFailedToLogIn):
        draft.authenticating = false;
        break;
      case getType(rootActions.session.setUserPermission): {
        draft.username = action.payload.username;
        draft.systemPermissions = action.payload.permissions;
        draft.locationEntityPermissions =
          action.payload.locationEntityPermissions;
        break;
      }
      case getType(rootActions.session.axiosInterceptorTokenCallFailed):
      case getType(rootActions.session.axiosInterceptorFailedToRefreshToken):
      case getType(rootActions.session.headerSignOutLinkClicked):
      case getType(rootActions.session.userLoggedOut): {
        const manageServer = draft.manageServer;
        const pages = draft.global.preferences.pages;
        let route = state.global.defaultRoute;
        route = getRouteFromPreferences(pages, route);
        // Reset state to default
        return {
          ...initialState(),
          view: route,
          authenticated: false,
          startupState: "complete",
          manageServer,
          loginView: draft.loginView,
        };
      }
      case getType(rootActions.manageMyAccount.updateEmailSuccess): {
        draft.username = draft.username.includes("\\")
          ? draft.username.split("\\")[0] + "\\" + action.payload
          : action.payload;
        break;
      }
      // #endregion

      // #region  CONTEXT LOADING
      case getType(rootActions.session.nonMetrologyMainFocusedJobFetched):
      case getType(rootActions.session.mainFocusedJobFetched): {
        draft.focusedJob.relatedJobs = {};
        draft.focusedJob.details = action.payload.job;
        draft.focusedJob.moreDetails = action.payload.moreDetails;
        draft.focusedJob.relatedFocusedJob.sortColumn = new Date(
          action.payload.job.start
        );
        draft.global.files.filesDataForFocusedJob =
          action.payload.filesInfoForSelectedJob;

        if (
          draft.focusedJob.details.type === "metrology" ||
          draft.focusedJob.details.type === "mastering"
        ) {
          const updatedMeasurementDetails = calculateCharacteristicActiveValues(
            draft.focusedJob.details.measurementCharacteristics,
            draft.focusedMachine.measurementDetails
          );
          draft.focusedMachine.measurementDetails = updatedMeasurementDetails;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.mostRecentJobUpdated): {
        draft.latestJob.relatedJobs = {};
        draft.latestJob.details = action.payload.job;
        draft.latestJob.moreDetails = action.payload.moreDetails;
        draft.latestJob.relatedFocusedJob.sortColumn = new Date(
          action.payload.job.start
        );
        draft.global.files.filesDataForFocusedJob =
          action.payload.filesInfoForSelectedJob;

        if (
          draft.latestJob.details.type === "metrology" ||
          draft.latestJob.details.type === "mastering"
        ) {
          const updatedMeasurementDetails = calculateCharacteristicActiveValues(
            draft.latestJob.details.measurementCharacteristics,
            draft.focusedMachine.measurementDetails
          );
          draft.focusedMachine.measurementDetails = updatedMeasurementDetails;
        }
        break;
      }
      case getType(rootActions.session.relatedFocusedJobFetched): {
        draft.focusedJob.relatedJobs[action.payload.job.id] =
          action.payload.job;
        break;
      }
      case getType(rootActions.machineAnalysis.relatedLatestJobFetched): {
        draft.latestJob.relatedJobs[action.payload.job.id] = action.payload.job;
        break;
      }
      case getType(rootActions.machineAnalysis.statesFetched): {
        draft.focusedMachine.states = {};
        for (const state of action.payload.states) {
          draft.focusedMachine.states[state.id] = state;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.statesSummaryFetched):
        draft.focusedMachine.stateSummary = action.payload[0];
        break;
      case getType(rootActions.machineAnalysis.statesDeltaFetched): {
        addAndRemoveEqually(
          draft.focusedMachine.states,
          action.payload.states,
          draft.cacheLimit
        );
        break;
      }
      case getType(rootActions.machineAnalysis.alertsFetched): {
        draft.focusedMachine.alerts = {};
        for (const alert of action.payload.alerts) {
          draft.focusedMachine.alerts[alert.id] = alert;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.alertsDeltaFetched): {
        addAndRemoveEqually(
          draft.focusedMachine.alerts,
          action.payload.alerts,
          draft.cacheLimit
        );
        break;
      }
      case getType(rootActions.machineAnalysis.processActionsFetched): {
        draft.focusedMachine.processActions = {};
        for (const event of action.payload.events) {
          draft.focusedMachine.processActions[event.id] = event;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.processActionsDeltaFetched): {
        addAndRemoveEqually(
          draft.focusedMachine.processActions,
          action.payload.events,
          draft.cacheLimit
        );
        break;
      }
      case getType(rootActions.machineAnalysis.jobSummariesFetched): {
        draft.focusedMachine.jobs = {};
        draft.latestJob.details = undefined;
        for (const job of action.payload.jobs) {
          draft.focusedMachine.jobs[job.id] = job;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.jobsSummariesDeltaFetched): {
        addAndRemoveEqually(
          draft.focusedMachine.jobs,
          action.payload.jobs,
          draft.cacheLimit
        );
        break;
      }
      case getType(rootActions.machineAnalysis.incompleteJobsFetched): {
        for (const job of action.payload.jobs) {
          const existingJob = draft.focusedMachine.jobs[job.id];
          if (
            existingJob &&
            existingJob.verdict === "No Verdict" &&
            job.verdict !== "No Verdict"
          )
            draft.focusedMachine.jobs[job.id] = job;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.unitDisplayHintsRequired): {
        draft.focusedMachine.awaitingHints = true;
        break;
      }
      case getType(
        rootActions.machineAnalysis.unitDisplayHintsFetchingFinished
      ): {
        draft.focusedMachine.awaitingHints = false;
        break;
      }
      case getType(rootActions.machineAnalysis.unitDisplayHintsFetched): {
        const orderedHints = action.payload.hints?.sort((a, b) =>
          b.created.localeCompare(a.created)
        );
        draft.focusedMachine.hints = orderedHints;
        draft.focusedMachine.awaitingHints = false;
        break;
      }
      case getType(rootActions.machineAnalysis.unitDisplayHintsDeltaFetched): {
        if (action.payload?.hints?.length) {
          const combined = draft.focusedMachine.hints
            .concat(action.payload.hints)
            .sort((a, b) => b.created.localeCompare(a.created));
          draft.focusedMachine.hints =
            combined.reduce(
              (arr, hint) =>
                !hint?.id || arr.some((h) => h.id === hint.id)
                  ? arr
                  : [...arr, hint],
              [] as UnitDisplayHint[]
            ) || ([] as UnitDisplayHint[]);
        }
        draft.focusedMachine.awaitingHints = false;
        break;
      }
      case getType(rootActions.machineAnalysis.liveRefreshCompleted): {
        draft.focusedMachine.lastFetched = action.payload.date;
        break;
      }
      case getType(rootActions.machineAnalysis.fetchingStarted): {
        const { loadingType } = action.payload;
        draft.focusedMachine.work[loadingType] = "Fetching";
        break;
      }
      case getType(rootActions.machineAnalysis.fetchingFinished): {
        const { loadingType, to } = action.payload;
        draft.focusedMachine.lastFetched = to;
        draft.focusedMachine.work[loadingType] = "Idle";
        break;
      }
      case getType(rootActions.machineAnalysis.setFocusedJobReducedEndDate): {
        draft.focusedJob.reducedEndDate = action.payload.date;
        break;
      }
      // #endregion

      // #region  MACHINE ANALYSIS
      case getType(
        rootActions.machineAnalysis.mainVisualisationResizeEventFired
      ): {
        // calculate new scale for timeline transform with previous state timeline width
        const newScale =
          action.payload.width /
          draft.visualisations.machineAnalysis.dimensions.timeline.width;
        // update width
        draft.visualisations.machineAnalysis.dimensions.timeline.width =
          action.payload.width;
        // width can be 0
        if (!newScale || newScale === Infinity || isNaN(newScale)) break;
        // update timeline transform x based on new scale
        draft.visualisations.machineAnalysis.transforms.timeline.x =
          draft.visualisations.machineAnalysis.transforms.timeline.x * newScale;
        break;
      }
      case getType(rootActions.machineAnalysis.lineGraphResizeEventFired): {
        // calculate new scale for timeline transform with previous state height
        const newScale =
          action.payload.height /
          draft.visualisations.machineAnalysis.dimensions.lineChart.height;
        // update height
        draft.visualisations.machineAnalysis.dimensions.lineChart.height =
          action.payload.height;
        // height can be 0
        if (!newScale || newScale === Infinity || isNaN(newScale)) break;
        // update transform y based on new scale
        Object.values(draft.visualisations.machineAnalysis.transforms.yAxes)
          .filter(notUndefined)
          .forEach((transform) => {
            transform.y = transform.y * newScale;
          });
        draft.visualisations.machineAnalysis.transforms.yDeviation.y =
          draft.visualisations.machineAnalysis.transforms.yDeviation.y *
          newScale;
        break;
      }
      case getType(rootActions.machineAnalysis.timelineZoomed): {
        // we are getting a new object each time so we want to make sure it's not equal by value
        if (
          !isEqual(
            draft.visualisations.machineAnalysis.transforms.timeline,
            action.payload.t
          )
        ) {
          draft.visualisations.machineAnalysis.transforms.timeline =
            action.payload.t;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.yAxisZoomed): {
        if (
          !draft.visualisations.machineAnalysis.transforms.yAxes[
            action.payload.id
          ] ||
          !isEqual(
            draft.visualisations.machineAnalysis.transforms.yAxes[
              action.payload.id
            ],
            action.payload.t
          )
        ) {
          draft.visualisations.machineAnalysis.transforms.yAxes[
            action.payload.id
          ] = action.payload.t;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.yAxisResetClicked): {
        draft.visualisations.machineAnalysis.transforms.yAxes[
          action.payload.id
        ] = {
          k: 1,
          y: 0,
        };
        break;
      }
      case getType(rootActions.machineAnalysis.yDeviationAxisZoomed): {
        if (
          !isEqual(
            draft.visualisations.machineAnalysis.transforms.yDeviation,
            action.payload.t
          )
        ) {
          draft.visualisations.machineAnalysis.transforms.yDeviation =
            action.payload.t;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.yAxisDeviationResetClicked): {
        draft.visualisations.machineAnalysis.transforms.yDeviation = {
          k: 1,
          y: 0,
        };
        break;
      }
      case getType(rootActions.machineAnalysis.machineSelectorMachineClicked): {
        draft.machineSelector.selected = action.payload;
        break;
      }
      case getType(
        rootActions.machineAnalysis.machineSelectorCancelButtonClicked
      ): {
        draft.machineSelector.selected = undefined;
        draft.machineSelector.visibility = "collapsed";
        break;
      }
      case getType(rootActions.machineAnalysis.machineSelectorButtonClicked): {
        draft.machineSelector.visibility =
          draft.machineSelector.visibility === "collapsed"
            ? "expanded"
            : "collapsed";
        draft.machineSelector.selected = undefined;
        break;
      }
      case getType(
        rootActions.machineAnalysis.machineSelectorEscapeKeyPressed
      ): {
        draft.machineSelector.visibility = "collapsed";
        draft.machineSelector.selected = undefined;
        break;
      }
      case getType(rootActions.machineAnalysis.alertsTimelineClicked):
        draft.focusedMachine.selectedAlerts = action.payload;
        break;
      case getType(rootActions.machineAnalysis.alertsTimelinePopupClosed):
        draft.focusedMachine.selectedAlerts = [];
        break;
      case getType(rootActions.machineAnalysis.jobsListCheckboxClicked): {
        draft.focusedMachine.filters.jobType = toggleArrayItem(
          draft.focusedMachine.filters.jobType,
          action.payload
        );
        break;
      }
      case getType(rootActions.machineAnalysis.alertTypeParetoClicked): {
        draft.focusedMachine.alertTypeParetoSelectedReason = action.payload;
        break;
      }
      case getType(
        rootActions.machineAnalysis.alertParetoTableCheckboxClicked
      ): {
        draft.focusedMachine.filters.alertName = toggleArrayItem(
          draft.focusedMachine.filters.alertName,
          action.payload.alertName
        );
        break;
      }
      case getType(
        rootActions.machineAnalysis.focusedJobViewTypeSwitchChanged
      ): {
        draft.userViewPreference.focusedOrLatestJobViewType =
          action.payload.value;
        break;
      }
      case getType(rootActions.machineAnalysis.nonAdditiveViewTypeSelected): {
        draft.userViewPreference.machineAnalysisView = additiveViews[0];
        break;
      }
      case getType(rootActions.machineAnalysis.sidebarSectionHeaderClicked): {
        const index = draft.userViewPreference.activeSidebarSections.indexOf(
          action.payload.section
        );
        if (index > -1) {
          draft.userViewPreference.activeSidebarSections.splice(index, 1);
        } else
          draft.userViewPreference.activeSidebarSections.push(
            action.payload.section
          );
        break;
      }
      case getType(rootActions.machineAnalysis.sidebarQueryChanged): {
        const { section, query } = action.payload;
        draft.userViewPreference.sidebarFilters[section] = query;
        break;
      }
      case getType(
        rootActions.machineAnalysis.latestJobViewMeasurementTypeSwitchChanged
      ):
      case getType(
        rootActions.machineAnalysis.focusedJobViewMeasurementTypeSwitchChanged
      ): {
        draft.userViewPreference.focusedOrLatestJobViewMeasurementType =
          action.payload.value;
        break;
      }
      case getType(rootActions.machineAnalysis.measurementTypesFetched): {
        const previousMeasurementDetails =
          draft.focusedMachine.measurementDetails;
        draft.focusedMachine.measurementDetails = {};
        action.payload.types.forEach((type) => {
          const id = createUniqueMeasurementID(type);
          const wasActive = previousMeasurementDetails[id]?.active;
          draft.focusedMachine.measurementDetails[id] = {
            // set default
            toleranceType: "Unknown",
            toleranceSubType: "Unknown",
            ...previousMeasurementDetails[id],
            ...type,
            active: wasActive ?? false,
          };
          const dataSeries = draft.focusedMachine.measurementValues[id];
          // Add unit to series details
          if (dataSeries?.data[0]?.unit) {
            draft.focusedMachine.measurementDetails[id]!.unit =
              dataSeries.data[0].unit;
          }
        });

        break;
      }
      case getType(rootActions.machineAnalysis.measurementTypesDeltaFetched): {
        action.payload.types.forEach((type) => {
          const id = createUniqueMeasurementID(type);
          draft.focusedMachine.measurementDetails[id] = {
            // set defaults
            toleranceType: "Unknown",
            toleranceSubType: "Unknown",
            active: false,
            ...draft.focusedMachine.measurementDetails[id],
            ...type,
          };
        });
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesTypesFetched): {
        const previousTimeSeriesDetails =
          draft.focusedMachine.timeSeriesDetails;
        draft.focusedMachine.timeSeriesDetails = {};
        action.payload.types.forEach((type) => {
          draft.focusedMachine.timeSeriesDetails[type.id] = {
            ...type,
            active: previousTimeSeriesDetails[type.id]?.active ?? false,
          };
        });
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesTypesDeltaFetched): {
        action.payload.types.forEach((type) => {
          draft.focusedMachine.timeSeriesDetails[type.id] = {
            ...type,
            active:
              draft.focusedMachine.timeSeriesDetails[type.id]?.active ?? false,
          };
        });
        break;
      }
      case getType(
        rootActions.machineAnalysis.measurementTypeSeriesListItemMainClicked
      ): {
        if (action.payload.series.type !== "measurement") break;
        const {
          payload: {
            series: { id },
          },
        } = action;
        draft.focusedMachine.mainMeasurementDetail =
          draft.focusedMachine.mainMeasurementDetail === id ? undefined : id;
        break;
      }
      case getType(rootActions.machineAnalysis.measurementValuesFetched): {
        const { data, featureName, name, from, to } = action.payload;
        const id = createUniqueMeasurementID({ featureName, name });
        draft.focusedMachine.measurementValues[id] = {
          data: data.sort((a, b) => (a.created < b.created ? -1 : 1)),
          params: { from, to },
        };

        // Add unit to series details
        if (data[0]?.unit) {
          draft.focusedMachine.measurementDetails[id]!.unit = data[0].unit;
        }
        break;
      }
      case getType(rootActions.machineAnalysis.measurementValuesDeltaFetched): {
        const { data, featureName, name, from, to } = action.payload;
        const id = createUniqueMeasurementID({ featureName, name });
        const previousMeasurementValues =
          draft.focusedMachine.measurementValues[id]?.data;
        draft.focusedMachine.measurementValues[id] = {
          data: (previousMeasurementValues
            ? [...previousMeasurementValues, ...data]
            : data
          ).sort((a, b) => a.created.localeCompare(b.created)),
          params: { from, to },
        };
        break;
      }
      case getType(rootActions.machineAnalysis.measurementPlotTypeChanged): {
        draft.focusedMachine.measurementPlotType = action.payload.value;
        break;
      }
      case getType(
        rootActions.machineAnalysis.timeSeriesTypesSeriesListItemClicked
      ): {
        const { series } = action.payload;
        const details = draft.focusedMachine.timeSeriesDetails[series.id];
        if (details) {
          details.active = !details.active;
        }
        const sc = draft.visualisations.machineAnalysis.seriesColours;
        if (!sc[series.id]) {
          sc[series.id] = getContrastingColour(Object.keys(sc).length);
        }
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesLimitsFetched): {
        const { data, id, from, to } = action.payload;
        draft.focusedMachine.timeSeriesLimits[id] = {
          data,
          params: { from, to },
        };
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesLimitsDeltaFetched): {
        const { data, id, from, to } = action.payload;
        const previousTimeSeriesLimits =
          draft.focusedMachine.timeSeriesLimits[id]?.data;
        draft.focusedMachine.timeSeriesLimits[id] = {
          data: previousTimeSeriesLimits
            ? [...previousTimeSeriesLimits, ...data]
            : data,
          params: { from, to },
        };
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesValuesFetched): {
        const { data, id, from, to } = action.payload;
        draft.focusedMachine.timeSeriesValues[id] = {
          data,
          params: { from, to },
        };
        break;
      }
      case getType(rootActions.machineAnalysis.timeSeriesValuesDeltaFetched): {
        const { data, id, from, to } = action.payload;
        const previousTimeSeriesValues =
          draft.focusedMachine.timeSeriesValues[id]?.data;

        const updatedTimeSeriesData = (
          previousTimeSeriesValues
            ? [...previousTimeSeriesValues, ...data]
            : data
        ).sort((a, b) => a[0].localeCompare(b[0]));

        draft.focusedMachine.timeSeriesValues[id] = {
          data: updatedTimeSeriesData,
          params: { from, to },
        };
        break;
      }
      case getType(rootActions.machineAnalysis.machineAnalysisTabSwitched): {
        setMachineAnalysisPageTab(action.payload.tab);
        draft.userViewPreference.machineAnalysisView = action.payload.tab;
        break;
      }
      case getType(
        rootActions.machineAnalysis.measurementTableMeasurementRowClicked
      ):
      case getType(
        rootActions.machineAnalysis.multiJobTableMeasurementTypeClicked
      ): {
        const id = createUniqueMeasurementID(action.payload.measurement);
        toggleMeasurementDetail(draft, state, id);
        break;
      }
      case getType(
        rootActions.machineAnalysis.measurementTypeSeriesListItemClicked
      ): {
        const {
          series: { id },
        } = action.payload;
        toggleMeasurementDetail(draft, state, id);
        break;
      }
      case getType(rootActions.machineAnalysis.multiJobTableHeadingClicked): {
        const sortColumn = action.payload.sortColumn;
        const sortDirection = action.payload.sortDirection;
        action.payload.jobType === "Focused"
          ? (draft.focusedJob.relatedFocusedJob.sortColumn = sortColumn)
          : (draft.latestJob.relatedFocusedJob.sortColumn = sortColumn);
        action.payload.jobType === "Focused"
          ? (draft.focusedJob.relatedFocusedJob.sortDirection = sortDirection)
          : (draft.latestJob.relatedFocusedJob.sortDirection = sortDirection);
        break;
      }
      case getType(rootActions.machineAnalysis.dataInvalidated): {
        draft.focusedMachine.timeSeriesValues = {};
        draft.focusedMachine.measurementValues = {};
        draft.focusedMachine.timeSeriesLimits = {};
        break;
      }
      // #endregion

      // #region CURRENT STATUS
      case getType(rootActions.currentStatus.fetchAlertsForMachine.success):
        draft.currentStatus.machineAlerts[action.payload.id] =
          action.payload.data;
        break;
      case getType(rootActions.currentStatus.fetchedMachineCycleData): {
        const cycleTime = action.payload.cycleTime;
        draft.currentStatus.cycleData[action.payload.machineId] = {
          max: cycleTime.cycleTimeMaxSeconds!,
          min: cycleTime.cycleTimeMinSeconds!,
          median: cycleTime.cycleTimeMedianSeconds!,
          mean: cycleTime.cycleTimeMeanSeconds!,
          numberOfJobs: cycleTime.numberOfJobs!,
        };
        draft.currentStatus.lastFetchedCycleTime = nowISOString();
        break;
      }
      case getType(rootActions.currentStatus.setViewMode): {
        draft.currentStatus.viewMode = action.payload;
        break;
      }
      case getType(
        rootActions.currentStatus.fetchJobSummariesForMachine.success
      ): {
        draft.currentStatus.machineJobSummaries[action.payload.id] =
          action.payload.data;
        break;
      }
      case getType(
        rootActions.currentStatus.fetchMachineCarouselData.success
      ): {
        const { from: aDayAgo } = last24Hours();
        let lastFetched = draft.currentStatus.lastFetchedCarouselData;
        for (const newData of action.payload.data) {
          const machine =
            draft.currentStatus.machineCarouselData[newData.sensor.machineId] ||
            [];
          const sensor = machine.find((v) => v.sensor.id === newData.sensor.id);

          const mergedData = {
            sensor: newData.sensor,
            limits: newData.limits || sensor?.limits || [],
            values: [...(sensor?.values || []), ...newData.values]
              .filter((x) => x[0] > aDayAgo)
              .sort(),
          };
          // Eliminate duplicates (sortedUniqBy is optimized for sorted arrays)
          mergedData.values = sortedUniqBy((x) => x[0], mergedData.values);
          // set lastFetched to timestamp of newest value
          lastFetched = [
            mergedData.values[mergedData.values.length - 1][0],
            lastFetched,
          ].sort()[1];

          draft.currentStatus.machineCarouselData[newData.sensor.machineId] = [
            ...machine.filter((v) => v.sensor.id !== newData.sensor.id),
            mergedData,
          ];
        }
        if (lastFetched)
          draft.currentStatus.lastFetchedCarouselData = lastFetched;
        break;
      }
      case getType(
        rootActions.currentStatus.fetchMeasurementCharacteristicsForJob.request
      ):
        draft.currentStatus.jobMeasurementCharacteristicsLoading.push(
          action.payload
        );
        break;
      case getType(
        rootActions.currentStatus.fetchMeasurementCharacteristicsForJob.success
      ):
        draft.currentStatus.jobMeasurementCharacteristics[action.payload.id] =
          action.payload.data;
        draft.currentStatus.jobMeasurementCharacteristicsLoading.splice(
          draft.currentStatus.jobMeasurementCharacteristicsLoading.indexOf(
            action.payload.id
          ),
          1
        );
        break;
      case getType(rootActions.currentStatus.alertBadgeClicked):
        draft.currentStatus.selectedMachineAlerts = action.payload.machineId;
        break;
      // #endregion

      // #region  GLOBAL
      case getType(rootActions.session.serverNotLicensed):
        draft.manageServer.serverLicenceState = "unlicensed";
        i18n.then((t) => {
          draft.global.notification = {
            id: "unlicensed",
            header: t("message-Unlicensed system"),
            body: [t("message-noValidLicence")],
            type: "error",
            show: true,
          };
        });
        break;
      case getType(rootActions.session.licenceServiceNotRunning):
        draft.manageServer.serverLicenceState = "LicenceServiceNotRunning";
        draft.manageServer.licenceErrorMessage = action.payload.message;
        draft.manageServer.licenceRetryErrorMessage =
          action.payload.retryMessage;
        break;
      case getType(rootActions.session.unLicencedServiceRunning):
        draft.manageServer.serverLicenceState = "UnlicensedServiceRunning";
        draft.manageServer.licenceErrorMessage = action.payload.message;
        draft.manageServer.licenceRetryErrorMessage =
          action.payload.retryMessage;
        break;
      case getType(rootActions.session.connectionFailedToCentralServer):
        draft.manageServer.serverLicenceState =
          "ConnectionFailedToCentralServer";
        draft.manageServer.licenceErrorMessage = action.payload.message;
        draft.manageServer.licenceRetryErrorMessage =
          action.payload.retryMessage;
        break;
      case getType(rootActions.session.showFooter):
        draft.manageServer.showFooter = action.payload.showFooter;
        break;
      case getType(rootActions.session.connectionToServerIsNotDefined):
      case getType(rootActions.session.serverIsLicensed):
        draft.manageServer.serverLicenceState = "licensed";
        break;
      case getType(rootActions.session.serverLicenceChecked): {
        if (
          Number(draft.manageServer.serverLicenceDaysRemaining) === 0 ||
          draft.manageServer.serverLicenceDaysRemaining !==
            action.payload.daysRemaining
        ) {
          draft.manageServer = {
            ...draft.manageServer,
            ...action.payload,
            serverLicenceDaysRemaining: action.payload.daysRemaining!,
          };
        }
        break;
      }
      case getType(rootActions.global.performanceNotificationRaised): {
        draft.global.notification = {
          id: "performance warning",
          header: performanceNotificationHeader,
          body: performanceNotificationBody,
          type: "warning",
          show: true,
        };
        break;
      }
      case getType(rootActions.global.jobSummaryViewChanged):
        draft.global.activeTab = action.payload;
        draft.global.files.downloadState = "initial";
        break;
      case getType(rootActions.global.toggleLocationNode): {
        const index = draft.global.expandedLocations.indexOf(action.payload[0]);

        if (!action.payload[1]) {
          if (index > -1) draft.global.expandedLocations.splice(index, 1);
          else draft.global.expandedLocations.push(action.payload[0]);
        } else {
          if (action.payload[1]) {
            if (!draft.global.expandedLocations.includes(action.payload[0])) {
              draft.global.expandedLocations.push(action.payload[0]);
            }
          } else {
            if (index > -1) draft.global.expandedLocations.splice(index, 1);
          }
        }
        break;
      }
      case getType(rootActions.global.expandAllLocations):
        draft.global.expandedLocations = draft.global.locations.map(
          (location) => location.id
        );
        break;
      case getType(rootActions.global.collapseAllLocations):
        draft.global.expandedLocations = [];
        break;
      case getType(rootActions.global.setMachineTypes):
        draft.global.machineTypes = orderBy([], [], action.payload);
        draft.global.lastFetched = new Date();
        break;
      case getType(rootActions.global.setMachineStatusUpdates):
        // Results are ordered by date ascending - get last one for each machine
        for (const machine of draft.global.machines) {
          const status = findLast(
            (s) => s.machineId === machine.id,
            action.payload
          )?.status;
          if (status) machine.machineStatus = status;
        }
        break;
      case getType(rootActions.global.setMachines):
        if (action.payload.length > 0) {
          draft.global.machines = action.payload;
          draft.global.lastFetched = new Date();
        }
        break;
      case getType(rootActions.global.setLocations):
        if (action.payload.length > 0) {
          draft.global.locations = action.payload;
          draft.global.lastFetched = new Date();
        }
        break;
      case getType(rootActions.manageMyAccount.setPreferences):
      case getType(rootActions.manageMyAccount.updatePreferencesPageSuccess): {
        if (!action.payload) return;
        const { pages } = action.payload;
        // Update default page when we set or load preferences
        let defaultRoute = "manage myaccount";

        if (pages?.length > 0) {
          if (pages.includes("Current Status")) defaultRoute = "current status";
          else if (pages.includes("Job Performance"))
            defaultRoute = "job performance";
          else if (pages.includes("Machine Performance"))
            defaultRoute = "machine performance";
        }
        draft.global.preferences.pages = pages;
        draft.global.defaultRoute = defaultRoute as Routes;
        break;
      }
      case getType(rootActions.global.setNotification):
        draft.global.notification = action.payload;
        break;
      case getType(rootActions.global.showDialogue):
        draft.global.dialogue = action.payload;
        break;
      case getType(rootActions.global.popupUpdated): {
        const { id, show, group, coord } = action.payload;
        const popup = getPopup(id, draft.global.popups);
        if (show === false) {
          draft.focusedMachine.selectedAlerts = [];
        }
        if (!popup) {
          if (show === false) return;
          // new popup
          const zIndex =
            draft.global.popups.length === 0
              ? popupDefaultZIndex
              : draft.global.popups[draft.global.popups.length - 1].zIndex + 1;
          draft.global.popups.push({
            id,
            group,
            coord: coord !== undefined ? coord : [0, 0, 0, 0],
            zIndex,
          });
          if (group) {
            draft.global.popups.forEach((p) => {
              if (p.id === id) return;
              else if (p.group && p.group === group)
                removePopup(p.id, draft.global.popups);
            });
          }
        } else {
          // existing popup
          if (show === false) {
            // remove
            removePopup(popup.id, draft.global.popups);
          } else {
            popup.coord = coord !== undefined ? coord : popup.coord;
            popup.group = group !== undefined ? group : popup.group;
            if (popup.group) {
              draft.global.popups.forEach((p) => {
                if (p.id === id) return;
                else if (p.group && p.group === popup.group)
                  removePopup(p.id, draft.global.popups);
              });
            }
          }
        }
        break;
      }
      case getType(rootActions.global.popupGroupCleared): {
        draft.global.popups.forEach((popup) => {
          if (popup.group && popup.group === action.payload)
            removePopup(popup.id, draft.global.popups);
        });
        break;
      }
      case getType(rootActions.global.machineToggled): {
        draft.global.expandedMachines = toggleArrayItem(
          draft.global.expandedMachines,
          action.payload
        );
        break;
      }
      case getType(rootActions.global.expandAllMachines): {
        draft.global.expandedMachines = draft.global.machines.map(
          ({ id }) => id
        );
        break;
      }
      case getType(rootActions.global.collapseAllMachines): {
        draft.global.expandedMachines = [];
        break;
      }
      case getType(rootActions.global.setFilesDetailsForSelectedJob): {
        draft.global.files.filesDetailsForFocusedJob = action.payload;
        break;
      }
      case getType(rootActions.global.setDownloadFileState): {
        draft.global.files.downloadState = action.payload;
        break;
      }
      // #endregion

      // #region  FILTER
      case getType(rootActions.filter.clear): {
        draft.filter = filterInitialState();
        break;
      }
      case getType(rootActions.filter.clearDraft): {
        const { payload } = action;
        payload === "alertLevel"
          ? (draft.filter.draft[payload] = defaultSelectedAlertLevels)
          : (draft.filter.draft[payload] = []);
        break;
      }
      case getType(rootActions.filter.resetDraft):
        draft.filter.draft[action.payload] =
          draft.filter.active[action.payload];
        break;
      case getType(rootActions.filter.applyDraft):
        draft.filter.active[action.payload] =
          draft.filter.draft[action.payload];
        break;
      case getType(rootActions.filter.addFilter): {
        const { payload } = action;
        draft.filter.draft[payload.key] = [
          ...new Set([
            ...(draft.filter.draft[payload.key] ?? []),
            payload.value,
          ]),
        ];
        break;
      }
      case getType(rootActions.filter.addSingleFilter): {
        const { payload } = action;
        draft.filter.draft[payload.key] = [payload.value];
        break;
      }
      case getType(rootActions.filter.removeFilter): {
        const removeFilterPayload = action.payload;
        if (typeof removeFilterPayload.value !== "string")
          draft.filter.draft[removeFilterPayload.key] = [];
        else
          draft.filter.draft[removeFilterPayload.key] = draft.filter.draft[
            removeFilterPayload.key
          ].filter((v) => v !== removeFilterPayload.value);
        break;
      }
      // #endregion

      // #region  JOB PERFORMANCE
      case getType(rootActions.jobPerformance.extendedSummaryFetched): {
        draft.jobPerformance.extendedSummaryFetched = true;
        break;
      }

      case getType(rootActions.jobPerformance.jobTypeClicked): {
        const { payload } = action;
        const index = draft.jobPerformance.openJobs.findIndex(
          (name) => name === payload.name
        );
        if (index > -1) {
          draft.jobPerformance.openJobs.splice(index, 1);
        } else {
          draft.jobPerformance.openJobs.push(payload.name);
        }
        break;
      }
      case getType(rootActions.jobPerformance.machineClicked): {
        const { machineId } = action.payload;
        const index = draft.jobPerformance.openMachines.findIndex(
          (id) => id === machineId
        );
        if (index > -1) {
          draft.jobPerformance.openMachines.splice(index, 1);
        } else {
          draft.jobPerformance.openMachines.push(machineId);
        }
        break;
      }
      case getType(rootActions.jobPerformance.collapseAllMachinesClicked): {
        const { jobName } = action.payload;
        draft.jobPerformance.openMachines =
          draft.jobPerformance.openMachines.filter(
            (m) => !matchesJob(m, { name: jobName })
          );
        break;
      }
      case getType(rootActions.jobPerformance.expandAllMachinesClicked): {
        const { jobName } = action.payload;
        const jobMachines = draft.jobPerformance.jobTypes.find(
          (jt) => jt.name === jobName
        )?.machines;
        if (jobMachines) {
          const uniques = new Set(draft.jobPerformance.openMachines);
          jobMachines.forEach((m) =>
            uniques.add(getRowId({ name: jobName }, { id: m }))
          );
          draft.jobPerformance.openMachines = [...uniques];
        }
        break;
      }
      case getType(rootActions.jobPerformance.metadataClicked): {
        const { metadataValue } = action.payload;
        const index = draft.jobPerformance.openMetadataValues.findIndex(
          (id) => id === metadataValue
        );
        if (index > -1) {
          draft.jobPerformance.openMetadataValues.splice(index, 1);
        } else {
          draft.jobPerformance.openMetadataValues.push(metadataValue);
        }
        break;
      }
      case getType(rootActions.jobPerformance.expandAllMetadataClicked): {
        const { jobName } = action.payload;
        const jobMetadata = draft.jobPerformance.jobTypes.find(
          (jt) => jt.name === jobName
        )?.metadataNames;
        if (jobMetadata) {
          const uniques = new Set(draft.jobPerformance.openMetadataValues);
          jobMetadata.forEach((m) => uniques.add(jobName + m));
          draft.jobPerformance.openMetadataValues = [...uniques];
        }
        break;
      }
      case getType(rootActions.jobPerformance.collapseAllMetadataClicked): {
        const { jobName } = action.payload;
        draft.jobPerformance.openMetadataValues =
          draft.jobPerformance.openMetadataValues.filter(
            (m) => !m.startsWith(`${jobName}`)
          );
        break;
      }
      case getType(rootActions.jobPerformance.expandAllClicked):
        if (draft.jobPerformance.activeMetadata === "Measuring Machine") {
          if (
            draft.jobPerformance.openJobs.length <
            draft.jobPerformance.jobTypes.length
          ) {
            draft.jobPerformance.openJobs = draft.jobPerformance.jobTypes.map(
              (j) => j.name
            );
          } else {
            draft.jobPerformance.openMachines =
              draft.jobPerformance.jobTypes.flatMap((jt) =>
                [...jt.machines].map((m) => getRowId(jt, { id: m }))
              );
          }
        } else {
          if (
            draft.jobPerformance.openJobs.length <
            draft.jobPerformance.jobTypes.length
          ) {
            draft.jobPerformance.openJobs = draft.jobPerformance.jobTypes.map(
              (j) => j.name
            );
          } else {
            draft.jobPerformance.openMetadataValues =
              draft.jobPerformance.jobTypes.flatMap((jt) =>
                jt.metadataNames.map((m) => jt.name + m)
              );
          }
        }
        break;
      case getType(rootActions.jobPerformance.collapseAllClicked):
        draft.jobPerformance.openMachines = [];
        draft.jobPerformance.openJobs = [];
        draft.jobPerformance.openMetadataValues = [];
        break;
      case getType(rootActions.jobPerformance.fetchingData):
        draft.jobPerformance.work = "Fetching";
        if (action.payload === "static") {
          draft.jobPerformance.openMetadataValues = [];
          draft.jobPerformance.openJobs = [];
        }
        draft.jobPerformance.openMachines = [];
        break;
      case getType(rootActions.jobPerformance.setDetailSort): {
        draft.jobPerformance.detailSortColumn = action.payload[0];
        draft.jobPerformance.detailSortDirection = action.payload[1];
        break;
      }
      case getType(rootActions.jobPerformance.setMachineJobAggregateSort): {
        draft.jobPerformance.machineJobAggregateSortColumn = action.payload[0];
        draft.jobPerformance.machineJobAggregateSortDirection =
          action.payload[1];
        break;
      }
      case getType(rootActions.jobPerformance.setMetadataJobAggregateSort): {
        draft.jobPerformance.metadataJobAggregateSortColumn = action.payload[0];
        draft.jobPerformance.metadataJobAggregateSortDirection =
          action.payload[1];
        break;
      }
      case getType(rootActions.jobPerformance.changeSortUnit):
        draft.jobPerformance.sortUnit = action.payload;
        break;
      case getType(rootActions.jobPerformance.changeSortColumn):
        draft.jobPerformance.sortColumn = action.payload;
        break;
      case getType(rootActions.jobPerformance.changeSortDirection):
        draft.jobPerformance.sortDirection = action.payload;
        break;
      case getType(rootActions.jobPerformance.workingOnData):
        draft.jobPerformance.work = "Working";
        break;
      case getType(rootActions.jobPerformance.workCompleted):
        draft.jobPerformance.work = "Idle";
        draft.jobPerformance.lastFetchedRange = action.payload.lastFetchedRange;
        break;
      case getType(rootActions.jobPerformance.jobsLoaded):
        draft.jobPerformance.jobTypes = action.payload.jobTypes;
        draft.jobPerformance.jobSummaries = action.payload.jobSummaries;
        break;
      case getType(rootActions.jobPerformance.metadataLoaded):
        draft.jobPerformance.metadataNamesList = action.payload;
        break;

      case getType(rootActions.jobPerformance.jobTypePerformanceCalculated):
        draft.jobPerformance.jobTypePerformances = action.payload.performance;
        break;
      case getType(rootActions.jobPerformance.machinePerformanceCalculated):
        draft.jobPerformance.machinePerformances = action.payload.performance;
        break;
      case getType(rootActions.jobPerformance.metadataPerformanceCalculated):
        draft.jobPerformance.metadataPerformance = action.payload.performance;
        break;
      case getType(rootActions.jobPerformance.groupJobByChanged):
        draft.jobPerformance.activeMetadata = action.payload;
        break;
      case getType(rootActions.jobPerformance.liveRefreshCompleted):
        draft.focusedMachine.lastFetched = action.payload.date;
        break;

      // #endregion

      // #region  MANAGE ASSETS
      case getType(rootActions.manageAssets.addNewDeviceButtonClicked):
        draft.manageAssets.createMachine = true;
        break;
      case getType(rootActions.manageAssets.updateMultipleNodesSuccessful): {
        if (!draft.global.expandedLocations.includes(action.payload.parentId))
          draft.global.expandedLocations.push(action.payload.parentId);
        break;
      }
      case getType(rootActions.manageAssets.activeTabChanged):
        draft.manageAssets.activeTab = action.payload;
        break;
      case getType(rootActions.manageAssets.sectionToggled):
        // @ts-expect-error string narrowing
        draft.manageAssets.openSections = toggleArrayItem(
          draft.manageAssets.openSections,
          action.payload
        );
        break;
      case getType(rootActions.manageAssets.controlButtonPressStateChanged):
        draft.manageAssets.mode =
          action.payload === "down" ? "multi" : "single";
        break;
      case getType(rootActions.manageAssets.treeviewNodeClicked):
        draft.manageAssets.createMachine = false;
        draft.manageAssets.connectionStatus = "cancelled";
        draft.manageAssets.connectionStatusBody = "";
        if (draft.manageAssets.mode === "single") {
          draft.manageAssets.multiSelectedNodes =
            draft.manageAssets.multiSelectedNodes.length === 1 &&
            draft.manageAssets.multiSelectedNodes[0] === action.payload
              ? []
              : (draft.manageAssets.multiSelectedNodes = [action.payload!]);
          draft.manageAssets.singleSelectedNode =
            draft.manageAssets.singleSelectedNode === action.payload &&
            !draft.manageAssets.multiSelectedNodes.includes(
              draft.manageAssets.singleSelectedNode!
            )
              ? null
              : action.payload;
        } else {
          const index = draft.manageAssets.multiSelectedNodes.indexOf(
            action.payload!
          );
          if (index > -1)
            draft.manageAssets.multiSelectedNodes.splice(index, 1);
          else draft.manageAssets.multiSelectedNodes.push(action.payload!);
        }
        break;
      case getType(rootActions.manageAssets.deleteSuccessful): {
        if (draft.manageAssets.singleSelectedNode === action.payload)
          draft.manageAssets.singleSelectedNode = null;
        break;
      }
      case getType(rootActions.manageAssets.addChildLocationSuccessful): {
        if (draft.view === "manage assets")
          draft.manageAssets.singleSelectedNode = action.payload.id;
        if (
          action.payload.parentId &&
          !draft.global.expandedLocations.includes(action.payload.parentId)
        )
          draft.global.expandedLocations.push(action.payload.parentId);
        break;
      }
      case getType(rootActions.manageAssets.confirmDeletion): {
        draft.manageAssets.confirmDeletion = true;
        draft.manageAssets.deleteEntityType = action.payload.type;
        break;
      }
      case getType(rootActions.manageAssets.confirmButtonClicked):
      case getType(rootActions.manageAssets.cancelButtonClicked): {
        draft.manageAssets.confirmDeletion = false;
        break;
      }
      case getType(rootActions.manageAssets.assignedLicencesFetched): {
        draft.assignedLicences = action.payload;
        draft.manageAssets.refreshInProgress = false;
        break;
      }
      case getType(rootActions.manageAssets.unassignedLicencesFetched): {
        draft.unassignedLicences = action.payload;
        draft.manageAssets.refreshInProgress = false;
        break;
      }
      case getType(rootActions.manageAssets.refreshButtonClicked): {
        draft.manageAssets.refreshInProgress = true;
        break;
      }
      case getType(rootActions.manageAssets.machineConfigurationFetched): {
        const conf = action.payload.configuration;
        if (!conf?.machineId) return;
        draft.manageAssets.machineConfigurations[conf.machineId] = conf;
        break;
      }
      case getType(rootActions.manageAssets.machineEditorFormUpdated): {
        const { payload } = action;
        if (!payload.id) return;

        const edits = draft.manageAssets.machineEdits;

        if (edits?.id === payload.id) {
          // continue editing same machine; merge values
          draft.manageAssets.machineEdits = {
            id: payload.id,
            name: payload.name ?? edits.name,
            make: payload.make ?? edits.make,
            model: payload.model ?? edits.model,
            serialNumber: payload.serialNumber ?? edits.serialNumber,
            ipAddress: payload.ipAddress ?? edits.ipAddress,
            locationId: payload.locationId ?? edits.locationId,
          };

          const configEdits = draft.manageAssets.machineConfigEdits;

          const changes = {
            machineId: payload.id,
            details: {
              dispatchMethod:
                payload.dispatchMethod ?? configEdits?.details?.dispatchMethod,
              controller: {
                make:
                  payload.controllerMake ??
                  configEdits?.details?.controller?.make,
                model:
                  payload.controllerModel ??
                  configEdits?.details?.controller?.model,
                units:
                  payload.controllerUnit ??
                  configEdits?.details?.controller?.units,
                unitsPrecision:
                  payload.controllerUnit === "mm"
                    ? 0.001
                    : payload.controllerUnit === "in"
                      ? 0.0001
                      : (payload.controllerUnitPrecision ??
                        configEdits?.details?.controller?.unitsPrecision),
              },
              connectionInfo: {
                ipAddress:
                  payload.connectionIpAddress ??
                  configEdits?.details?.connectionInfo?.ipAddress,
                hostname:
                  payload.connectionHostname ??
                  configEdits?.details?.connectionInfo?.hostname,
                portNumber:
                  payload.connectionPortNumber ??
                  configEdits?.details?.connectionInfo?.portNumber,
                deviceNumber:
                  payload.connectionDeviceNumber ??
                  configEdits?.details?.connectionInfo?.deviceNumber,
                channel:
                  payload.connectionChannel ??
                  configEdits?.details?.connectionInfo?.channel,
                appName:
                  payload.connectionAppName ??
                  configEdits?.details?.connectionInfo?.appName,
                appPassword:
                  payload.connectionAppPassword ??
                  configEdits?.details?.connectionInfo?.appPassword,
                connectionType:
                  payload.connectionType ??
                  configEdits?.details?.connectionInfo?.connectionType,
                connectionTimeoutSecs:
                  payload.connectionTimeoutSecs ??
                  configEdits?.details?.connectionInfo?.connectionTimeoutSecs,
                useServer:
                  payload.connectionUseServer ??
                  configEdits?.details?.connectionInfo?.useServer,
                addressType:
                  payload.connectionAddressType ??
                  configEdits?.details?.connectionInfo?.addressType,
              },
              configurationInfo: {
                syncVariable:
                  payload.configurationSyncVariable ??
                  configEdits?.details?.configurationInfo?.syncVariable,
                alarmVariable:
                  payload.configurationAlarmVariable ??
                  configEdits?.details?.configurationInfo?.alarmVariable,
              },
            },
          };
          const changesCleaned = removeUndefinedValues(changes);

          draft.manageAssets.machineConfigEdits = changesCleaned;
        } else {
          // first-time edits to this machine; replace any existing values
          draft.manageAssets.machineEdits = action.payload;
          // draft.manageAssets.machineConfigEdits =

          const changes = {
            machineId: payload.id,
            details: {
              dispatchMethod: payload.dispatchMethod ?? undefined,
              controller: {
                make: payload.controllerMake ?? undefined,
                model: payload.controllerModel ?? undefined,
                units: payload.controllerUnit ?? undefined,
                unitsPrecision: payload.controllerUnitPrecision ?? undefined,
              },
              connectionInfo: {
                ipAddress: payload.connectionIpAddress ?? undefined,
                hostname: payload.connectionHostname ?? undefined,
                portNumber: payload.connectionPortNumber ?? undefined,
                deviceNumber: payload.connectionDeviceNumber ?? undefined,
                channel: payload.connectionChannel ?? undefined,
                appName: payload.connectionAppName ?? undefined,
                appPassword: payload.connectionAppPassword ?? undefined,
                connectionType: payload.connectionType ?? undefined,
                connectionTimeoutSecs:
                  payload.connectionTimeoutSecs ?? undefined,
                useServer: payload.connectionUseServer ?? undefined,
                addressType: payload.connectionAddressType ?? undefined,
              },
              configurationInfo: {
                syncVariable: payload.configurationSyncVariable ?? undefined,
                alarmVariable: payload.configurationAlarmVariable ?? undefined,
              },
            },
          };

          const changesCleaned = removeUndefinedValues(changes);
          draft.manageAssets.machineConfigEdits = changesCleaned;
        }
        break;
      }
      case getType(
        rootActions.manageAssets.machineEditorSaveButtonClickedSuccess
      ): {
        const machine = action.payload.machine;
        if (!machine) return;
        draft.global.machines = draft.global.machines.map((m) => {
          return m.id === machine.id ? { ...m, ...machine } : m;
        });
        const conf = action.payload.configuration;
        if (!conf?.machineId) return;
        draft.manageAssets.machineConfigurations[conf?.machineId] = conf;
        break;
      }
      case getType(rootActions.manageAssets.setFormDataInvalidated): {
        draft.manageAssets.formDataInvalidated = action.payload;
        break;
      }
      case getType(rootActions.manageAssets.setStatusForTestConnection): {
        draft.manageAssets.connectionStatus = action.payload.connectionStatus;
        draft.manageAssets.connectionStatusBody =
          action.payload.connectionStatusBody;
        break;
      }
      case getType(rootActions.manageAssets.setControllerResponseFromMachine): {
        draft.manageAssets.controllerResponseFromMachine = action.payload;
        break;
      }
      // #endregion

      // #region  MANAGE MACHINE PROVISIONING
      case getType(rootActions.manageProvisioning.provisionRequestsFetched): {
        draft.manageProvisioning.machines = action.payload;
        break;
      }
      case getType(rootActions.manageProvisioning.deviceListItemClicked): {
        draft.manageProvisioning.selectedRegistrationId = action.payload;
        break;
      }
      case getType(rootActions.manageProvisioning.machineApprovalDeleted):
      case getType(rootActions.manageProvisioning.machineApproved): {
        draft.manageProvisioning.machines =
          draft.manageProvisioning.machines.filter(
            (item) => item.registrationId !== action.payload
          );
        draft.manageProvisioning.selectedRegistrationId = "";
        break;
      }
      // #endregion

      // #region  MANAGE MY ACCOUNT
      case getType(rootActions.manageMyAccount.activeTabChanged):
        draft.manageMyAccount.activeTab = action.payload;
        break;
      // #endregion

      // #region  MANAGE MY CONNECTORS
      case getType(rootActions.manageConnectors.setMyConnectorsDetails):
        draft.manageConnectors.connectorDetails = action.payload;
        break;
      // #endregion

      // #region  MANAGE MY SETTINGS
      case getType(rootActions.manageSettings.sectionToggled):
        draft.manageSettings.openSections = action.payload;
        break;

      case getType(rootActions.manageSettings.settingsUpdated):
        draft.manageSettings.languageSelected = action.payload.languageSelected;
        draft.global.notification = {
          header: settingsNotificationHeader,
          body: [""],
          type: "success",
          show: true,
          id: "settings",
        };
        try {
          localStorage.setItem("i18nextLng", action.payload.languageSelected);
        } catch (ex) {
          console.info("localStorage unavailable");
        }
        break;

      // #endregion

      // #region MANAGE NOTIFICATIONS

      case getType(rootActions.manageNotifications.notificationsNotFound):
        draft.manageNotifications.notificationSupported = false;
        break;
      case getType(
        rootActions.manageNotifications.setNotificationsSettingsActivateStatus
      ):
        draft.manageNotifications.notificationIsActivated = action.payload;
        break;
      case getType(rootActions.manageNotifications.notificationsFetched): {
        draft.manageNotifications.notificationSupported = true;
        draft.manageNotifications.notificationValues = action.payload;
        draft.manageNotifications.activeLocations = [];
        draft.manageNotifications.activeMachines = [];
        // set manage devices select all machines checkbox based on initial notifications
        if (
          action.payload &&
          action.payload.length &&
          action.payload.length === draft.global.machines.length
        )
          draft.manageNotifications.selectAllMachinesAndLocations = true;
        else draft.manageNotifications.selectAllMachinesAndLocations = false;
        // set manage devices expand collapse based on initial notifications
        if (action.payload && action.payload.length)
          draft.manageNotifications.toggleManageDevices = true;
        else draft.manageNotifications.toggleManageDevices = false;
        // set toggled location id's and checked machines id's based on initial notifications
        if (action.payload && action.payload.length) {
          action.payload.map((f) => {
            const locId = draft.global.machines.find(
              (m) => m.id == f.machineId
            )?.locationId;
            const ancestors = draft.global.locations.find(
              (f) => f.id === locId
            )?.ancestors;
            if (
              draft.manageNotifications.toggledLocationIds.filter(
                (x) => x !== locId
              )
            )
              draft.manageNotifications.toggledLocationIds = [
                ...draft.manageNotifications.toggledLocationIds,
                locId ?? "",
              ];
            if (ancestors)
              draft.manageNotifications.toggledLocationIds = [
                ...draft.manageNotifications.toggledLocationIds,
                ...ancestors,
              ];
          });
          // set locations checked for selected machines
          action.payload.map((f) => {
            const locId = draft.global.machines.find(
              (m) => m.id == f.machineId
            )?.locationId;

            const location = draft.global.locations.find((m) => m.id == locId);

            const ancestors = getAncestors(
              { id: locId!, parentId: location?.parentId },
              draft.global.locations,
              location?.ancestors
            );

            if (
              draft.manageNotifications.activeLocations.filter(
                (x) => x !== locId
              )
            )
              draft.manageNotifications.activeLocations = [
                ...new Set([
                  ...draft.manageNotifications.activeLocations,
                  locId ?? "",
                  ...ancestors,
                ]),
              ];
          });
          // set active machines based on initial notifications
          draft.manageNotifications.activeMachines = [
            ...action.payload.map((m) => m.machineId),
          ];

          // toggle settings based on initial content on page load if any active settings
          const event = action.payload[0].events;
          const alert = event.find((e) => e.type === "Alert");
          if (!alert) draft.manageNotifications.activeMachineAlerts = [];
          const jobEndData = event.filter((e) => e.type === "JobEnd");
          if (!jobEndData.length) {
            draft.manageNotifications.activeJobStatuses = [];
            draft.manageNotifications.activeJobVerdicts = [];
          }
          const machineStatus = event.find((e) => e.type === "MachineStatus");
          if (!machineStatus)
            draft.manageNotifications.activeMachineStatuses = [];
          let jobStatuses: string[] = [];
          if (jobEndData && jobEndData.length)
            jobStatuses = jobEndData[0].filters![0].values.filter(
              (f) => f !== "Started"
            );
          if (event.some((e) => e.type === "JobStart"))
            jobStatuses = [...jobStatuses, "Started"];
          else jobStatuses = [...jobStatuses];

          let verdicts: string[] = [];
          if (jobEndData && jobEndData.length)
            verdicts = jobEndData[0].filters![1].values;
          if (
            alert &&
            alert?.filters![0].values &&
            alert?.filters[0].values?.length > 0 &&
            !draft.manageNotifications.openSections.includes("machine alerts")
          ) {
            draft.manageNotifications.openSections.push("machine alerts");
            draft.manageNotifications.headingControllers = [
              ...draft.manageNotifications.headingControllers,
              "machine alerts",
            ];
          }
          if (
            machineStatus &&
            machineStatus?.filters![0].values &&
            machineStatus?.filters[0].values.length > 0 &&
            !draft.manageNotifications.openSections.includes("machine status")
          ) {
            draft.manageNotifications.openSections.push("machine status");
            draft.manageNotifications.headingControllers = [
              ...draft.manageNotifications.headingControllers,
              "machine status",
            ];
          }

          if (
            jobStatuses &&
            jobStatuses?.length &&
            !draft.manageNotifications.openSections.includes("job status")
          ) {
            draft.manageNotifications.openSections.push("job status");
            draft.manageNotifications.headingControllers = [
              ...draft.manageNotifications.headingControllers,
              "job status",
            ];
          }

          if (
            verdicts &&
            verdicts?.length > 0 &&
            !draft.manageNotifications.openSections.includes("job verdict")
          ) {
            draft.manageNotifications.openSections.push("job verdict");
            draft.manageNotifications.headingControllers = [
              ...draft.manageNotifications.headingControllers,
              "job verdict",
            ];
          }
        }
        break;
      }

      case getType(rootActions.manageNotifications.sectionToggled):
        // @ts-expect-error string narrowing
        draft.manageNotifications.openSections = toggleArrayItem(
          draft.manageNotifications.openSections,
          action.payload
        );
        break;

      case getType(rootActions.manageNotifications.setToggledLocationIds): {
        draft.manageNotifications.toggledLocationIds = toggleArrayItem(
          draft.manageNotifications.toggledLocationIds,
          action.payload
        );
        break;
      }

      case getType(rootActions.manageNotifications.setActiveMachineAlerts):
        draft.manageNotifications.activeMachineAlerts = action.payload;
        break;
      case getType(rootActions.manageNotifications.setActiveMachineStatuses):
        draft.manageNotifications.activeMachineStatuses = action.payload;
        break;
      case getType(rootActions.manageNotifications.setActiveJobStatuses):
        draft.manageNotifications.activeJobStatuses = action.payload;
        break;
      case getType(rootActions.manageNotifications.setActiveJobVerdicts):
        draft.manageNotifications.activeJobVerdicts = action.payload;
        break;

      case getType(rootActions.manageNotifications.addActiveLocation): {
        draft.manageNotifications.activeLocations = [
          ...draft.manageNotifications.activeLocations,
          action.payload,
        ];
        let activeMachines = draft.global.machines
          .filter((m) => m.locationId === action.payload)
          .map((m) => m.id);

        if (action.payload === "")
          activeMachines = draft.global.machines
            .filter((m) => !m.locationId)
            .map((m) => m.id);

        draft.manageNotifications.activeMachines = [
          ...draft.manageNotifications.activeMachines,
          ...activeMachines,
        ];
        break;
      }
      case getType(rootActions.manageNotifications.removeActiveLocation): {
        draft.manageNotifications.activeLocations =
          draft.manageNotifications.activeLocations.filter(
            (x) => x !== action.payload
          );
        if (action.payload)
          draft.manageNotifications.activeMachines = [
            ...draft.manageNotifications.activeMachines.filter(
              (p) =>
                !draft.global.machines
                  .filter((mc) => mc.locationId === action.payload)
                  .map((m) => m.id)
                  .includes(p)
            ),
          ];
        if (action.payload === "")
          draft.manageNotifications.activeMachines = [
            ...draft.manageNotifications.activeMachines.filter(
              (p) =>
                !draft.global.machines
                  .filter((mc) => !mc.locationId)
                  .map((m) => m.id)
                  .includes(p)
            ),
          ];
        break;
      }
      case getType(rootActions.manageNotifications.setActiveMachines): {
        const locations = draft.global.locations;
        const activeLocations = draft.manageNotifications.activeLocations.map(
          (m) => m
        );
        draft.manageNotifications.activeMachines = toggleArrayItem(
          draft.manageNotifications.activeMachines.map((m) => m),
          action.payload
        );
        if (draft.manageNotifications.activeMachines.includes(action.payload)) {
          const locIdOfSelectedMachine = draft.global.machines.find(
            (m) => m.id == action.payload
          )?.locationId;

          if (locIdOfSelectedMachine === "")
            draft.manageNotifications.activeLocations = [
              ...new Set([...activeLocations, locIdOfSelectedMachine]),
            ];
          else {
            const location = draft.global.locations.find(
              (m) => m.id == locIdOfSelectedMachine
            );
            const ancestors = getAncestors(
              { id: locIdOfSelectedMachine!, parentId: location?.parentId },
              locations,
              location?.ancestors
            );
            draft.manageNotifications.activeLocations = [
              ...new Set([
                ...activeLocations,
                locIdOfSelectedMachine ?? "",
                ...ancestors,
              ]),
            ];
          }
        }
        break;
      }
      case getType(rootActions.manageNotifications.selectAllClicked): {
        draft.manageNotifications.selectAllMachinesAndLocations = true;
        draft.manageNotifications.activeLocations = draft.global.locations.map(
          (m) => m.id
        );
        draft.manageNotifications.activeMachines = draft.global.machines.map(
          (m) => m.id
        );
        break;
      }
      case getType(rootActions.manageNotifications.clearAllClicked): {
        draft.manageNotifications.selectAllMachinesAndLocations = false;
        draft.manageNotifications.activeLocations = [];
        draft.manageNotifications.activeMachines = [];
        break;
      }
      case getType(rootActions.manageNotifications.resetClicked): {
        draft.manageNotifications.activeLocations = [];
        draft.manageNotifications.activeMachines = [];
        if (draft.manageNotifications.notificationValues) {
          draft.manageNotifications.notificationValues.map((f) => {
            const locId = draft.global.machines.find(
              (m) => m.id == f.machineId
            )?.locationId;

            if (
              draft.manageNotifications.activeLocations.filter(
                (x) => x !== locId
              )
            )
              draft.manageNotifications.activeLocations = [
                ...draft.manageNotifications.activeLocations,
                locId ?? "",
              ];
          });

          draft.manageNotifications.activeMachines = [
            ...draft.manageNotifications.notificationValues.map(
              (m) => m.machineId
            ),
          ];
        }
        break;
      }
      case getType(
        rootActions.manageNotifications.toggleManageDevicesClicked
      ): {
        draft.manageNotifications.toggleManageDevices = action.payload;
        break;
      }

      case getType(
        rootActions.manageNotifications.settingHeadingCheckboxClicked
      ): {
        draft.manageNotifications.headingControllers = action.payload;
        break;
      }
      // #endregion

      // #region  MANAGE USERS
      case getType(rootActions.manageUsers.addNewUserButtonClicked):
        draft.manageUsers.mode = "create";
        draft.manageUsers.selectedUserId = "";
        break;
      case getType(rootActions.manageUsers.userListItemClicked):
        draft.manageUsers.selectedUserId = action.payload;
        draft.manageUsers.mode = "modify";
        break;
      case getType(rootActions.manageUsers.usersFetched):
        draft.manageUsers.users = action.payload;
        break;
      // optimistic update
      case getType(rootActions.manageUsers.activateUserButtonClicked):
        draft.manageUsers.users.find(
          (u) => u.id === action.payload.id
        )!.active = true;
        break;
      // optimistic update
      case getType(rootActions.manageUsers.deactivateUserButtonClicked):
        draft.manageUsers.users.find(
          (u) => u.id === action.payload.id
        )!.active = false;
        break;
      // optimistic update
      case getType(rootActions.manageUsers.saveChangesButtonClicked):
        draft.manageUsers.users = draft.manageUsers.users.map((u) => {
          return u.id === action.payload.id ? action.payload : u;
        });
        break;
      case getType(rootActions.manageUsers.confirmDeletion): {
        draft.manageUsers.confirmDeletion = true;
        break;
      }
      case getType(rootActions.manageUsers.cancelButtonClicked):
      case getType(rootActions.manageUsers.confirmButtonClicked): {
        draft.manageUsers.confirmDeletion = false;
        break;
      }
      case getType(rootActions.manageUsers.userCreated): {
        draft.manageUsers.selectedUserId = action.payload.userId;
        draft.manageUsers.mode = "modify";
        break;
      }
      // #endregion

      // #region  MANAGE CLIENTS
      case getType(rootActions.manageClients.clientsFetched):
        draft.manageClients.clients = action.payload ?? [];
        break;
      case getType(rootActions.manageClients.clientListItemClicked):
        draft.manageClients.selectedClientId = action.payload;
        draft.manageClients.selectedClientSecret = undefined;
        draft.manageClients.mode = "modify";
        break;
      case getType(rootActions.manageClients.activateClientButtonClicked):
        draft.manageClients.clients.find(
          (u) => u.id === action.payload.id
        )!.active = true;
        break;
      case getType(rootActions.manageClients.deactivateClientButtonClicked):
        draft.manageClients.clients.find(
          (u) => u.id === action.payload.id
        )!.active = false;
        break;
      case getType(rootActions.manageClients.confirmDeletion): {
        draft.manageClients.confirmDeletion = true;
        break;
      }
      case getType(rootActions.manageClients.cancelButtonClicked):
      case getType(rootActions.manageClients.confirmButtonClicked): {
        draft.manageClients.confirmDeletion = false;
        break;
      }
      case getType(rootActions.manageClients.addNewClientButtonClicked):
        draft.manageClients.mode = "create";
        draft.manageClients.selectedClientId = "";
        draft.manageClients.selectedClientSecret = undefined;
        break;
      case getType(rootActions.manageClients.clientCreated): {
        draft.manageClients.selectedClientId = action.payload.clientId;
        draft.manageClients.selectedClientSecret =
          action.payload.clientSecret ?? undefined;
        draft.manageClients.mode = "modify";
        break;
      }
      // #endregion

      // #region  MACHINE PERFORMANCE
      case getType(rootActions.machinePerformance.alertCountsFetched): {
        const { machineId, alertCount, machineAlerts } = action.payload;
        const existingRecord =
          draft.machinePerformance.machinePerformances[machineId];
        const baseRecord = { ...emptyMachinePerformance(), ...existingRecord };
        draft.machinePerformance.machinePerformances[machineId] = {
          ...baseRecord,
          totalErrorAlerts: alertCount,
        };
        draft.machinePerformance.machineAlerts[machineId] = machineAlerts;
        break;
      }
      case getType(rootActions.machinePerformance.alertCountsDeltaFetched): {
        const { machineId, alertCount, from } = action.payload;
        const existingRecord =
          draft.machinePerformance.machinePerformances[machineId];
        if (existingRecord && draft.machinePerformance.lastFetched! <= from) {
          existingRecord.totalErrorAlerts += alertCount;
        }
        break;
      }
      case getType(rootActions.machinePerformance.statusesFetched): {
        const { statuses, machineId } = action.payload;
        const { runningTime, percent, machineStatuses } =
          calculateMachineStatuses(statuses);
        const existingRecord =
          draft.machinePerformance.machinePerformances[machineId];
        const baseRecord = { ...emptyMachinePerformance(), ...existingRecord };
        draft.machinePerformance.machinePerformances[machineId] = {
          ...baseRecord,
          statusOk: {
            seconds: runningTime,
            percent: percent,
          },
        };
        draft.machinePerformance.machineStatuses[machineId] = machineStatuses;
        break;
      }
      case getType(rootActions.machinePerformance.statusesDeltaFetched): {
        const { statuses, machineId, from } = action.payload;
        // TODO: We don't update machineStatuses on delta fetch, because we haven't retained the data needed to calculate them
        const { runningTime } = calculateMachineStatuses(statuses);
        const existingRecord =
          draft.machinePerformance.machinePerformances[machineId];
        if (existingRecord && draft.machinePerformance.lastFetched! <= from) {
          existingRecord.statusOk.seconds += runningTime;
        }
        break;
      }
      case getType(rootActions.machinePerformance.jobAggregateFetched): {
        const {
          payload: { machineId, jobAggregate, topJobStats },
        } = action;
        const existingJobAggregateRecord =
          draft.machinePerformance.machineJobAggregates[machineId];
        const baseRecord = {
          ...emptyJobAggregate(),
          ...existingJobAggregateRecord,
        };
        draft.machinePerformance.machineJobAggregates[machineId] = {
          ...baseRecord,
          ...jobAggregate,
        };
        draft.machinePerformance.machineJobStats[machineId] = topJobStats;
        break;
      }
      case getType(rootActions.machinePerformance.jobAggregateDeltaFetched): {
        const { machineId, jobAggregate, from } = action.payload;
        // TODO: We don't update machineJobStats on delta fetch, because we haven't retained the data needed to calculate them
        const existing =
          draft.machinePerformance.machineJobAggregates[machineId];
        if (existing && draft.machinePerformance.lastFetched! <= from) {
          draft.machinePerformance.machineJobAggregates[machineId] =
            updateExistingJobAggregateRecord(jobAggregate, existing);
        }
        break;
      }
      case getType(rootActions.machinePerformance.fetchingStarted): {
        draft.machinePerformance.work = "Fetching";
        break;
      }
      case getType(rootActions.machinePerformance.fetchingFinished): {
        draft.machinePerformance.lastFetched = action.payload.to;
        draft.machinePerformance.work = "Idle";
        break;
      }
      case getType(rootActions.machinePerformance.headerCellClicked): {
        const { sortColumn, sortDirection } = action.payload;
        draft.machinePerformance.sortColumn = sortColumn;
        draft.machinePerformance.sortDirection = sortDirection;
        break;
      }
      case getType(rootActions.machinePerformance.machineRowClicked): {
        const { payload } = action;
        const index = draft.machinePerformance.openMachines.findIndex(
          (id) => id === payload.machineId
        );
        if (index > -1) {
          draft.machinePerformance.openMachines.splice(index, 1);
        } else {
          draft.machinePerformance.openMachines.push(payload.machineId);
        }
        break;
      }
      case getType(rootActions.machinePerformance.collapseAllClicked):
        draft.machinePerformance.openMachines = [];
        break;
      case getType(rootActions.machinePerformance.expandAllClicked):
        draft.machinePerformance.openMachines = draft.global.machines.map(
          (machine) => machine.id
        );
        break;

      // process updates
      case getType(rootActions.processUpdates.offsetAdjustmentFetched): {
        draft.processUpdates.toolOffsetApplied = action.payload.events;
        break;
      }
      case getType(rootActions.processUpdates.offsetAdjustmentDeltaFetched): {
        const newData = action.payload.events;
        const oldData = draft.processUpdates.toolOffsetApplied;
        if (newData.length)
          draft.processUpdates.toolOffsetApplied = [
            ...(oldData ?? []),
            ...newData,
          ];
        break;
      }
      case getType(rootActions.processUpdates.setCalculatedProcessUpdates): {
        draft.processUpdates.processUpdatesPerf =
          action.payload.processUpdatesPerf;
        draft.processUpdates.processUpdateDetails =
          action.payload.processUpdateDetails;
        draft.processUpdates.processUpdateDetailsMore =
          action.payload.processUpdateDetailsMore;
        break;
      }
      case getType(rootActions.processUpdates.perfHeaderCellClicked): {
        draft.processUpdates.processUpdatesPerfSortColumn =
          action.payload.sortColumn;
        draft.processUpdates.processUpdatesPerfSortDirection =
          action.payload.sortDirection;
        break;
      }

      case getType(rootActions.processUpdates.fetchingStarted): {
        draft.processUpdates.work = "Fetching";
        break;
      }
      case getType(rootActions.processUpdates.fetchingFinished): {
        draft.processUpdates.lastFetched = action.payload.to;
        draft.processUpdates.work = "Idle";
        break;
      }
      case getType(rootActions.processUpdates.toolOffsetTypeRowClicked): {
        const { payload } = action;
        const index = draft.processUpdates.openToolOffsets.findIndex(
          (name) => name === payload.name
        );
        if (index > -1) {
          draft.processUpdates.openToolOffsets.splice(index, 1);
        } else {
          draft.processUpdates.openToolOffsets.push(payload.name);
        }
        break;
      }
      case getType(rootActions.processUpdates.collapseAllClicked):
        draft.processUpdates.openProcessUpdatesDetailsList = [];
        draft.processUpdates.openToolOffsets = [];
        break;
      case getType(rootActions.processUpdates.expandAllClicked):
        if (
          draft.processUpdates.openToolOffsets.length <
          Object.keys(draft.processUpdates.processUpdatesPerf).length
        ) {
          draft.processUpdates.openToolOffsets = Object.keys(
            draft.processUpdates.processUpdatesPerf
          ).map((name) => name);
        } else {
          draft.processUpdates.openProcessUpdatesDetailsList = Object.keys(
            draft.processUpdates.processUpdateDetails
          );
        }
        break;
      case getType(
        rootActions.processUpdates.offsetAdjustmentTableHeaderClicked
      ): {
        draft.processUpdates.offsetAdjustmentSortColumn = action.payload[0];
        draft.processUpdates.offsetAdjustmentSortDirection = action.payload[1];
        break;
      }
      case getType(rootActions.processUpdates.detailsTableHeaderCellClicked): {
        draft.processUpdates.processUpdatesDetailsSortColumn =
          action.payload[0];
        draft.processUpdates.processUpdatesDetailsSortDirection =
          action.payload[1];
        break;
      }
      case getType(rootActions.processUpdates.detailsTableRowClicked): {
        const { payload } = action;
        const index =
          draft.processUpdates.openProcessUpdatesDetailsList.findIndex(
            (name) => name === payload.name
          );
        if (index > -1) {
          draft.processUpdates.openProcessUpdatesDetailsList.splice(index, 1);
        } else {
          draft.processUpdates.openProcessUpdatesDetailsList.push(payload.name);
        }
        break;
      }
      case getType(rootActions.processUpdates.detailsTableCollapseAllClicked):
        draft.processUpdates.openProcessUpdatesDetailsList = [];
        break;
      case getType(rootActions.processUpdates.detailsTableExpandAllClicked):
        draft.processUpdates.openProcessUpdatesDetailsList = action.payload;
        break;
      case getType(
        rootActions.processUpdates.detailsTableInnerTableHeaderCellClicked
      ): {
        draft.processUpdates.detailsInnerTableSortColumn = action.payload[0];
        draft.processUpdates.detailsInnerTableSortDirection = action.payload[1];
        break;
      }
      // #endregion
    }
    // Note - State that should be transitioned regardless of the action
    try {
      const value = JSON.parse(
        localStorage?.getItem("location-pathname-search") || "{}"
      );

      if (
        action.type !==
          getType(rootActions.session.passwordResetTokenUpdated) &&
        value?.pathname !== "/reset-password" &&
        draft.authenticated
      )
        draft.params.token = null;
    } catch (ex) {
      console.info("localStorage unavailable");
    }
  });

// Default to the Job tab on Machine Analysis, or the Process tab for Additive machines
function setMachineAnalysisTab(draft: State, defaultTab?: boolean) {
  const tabName =
    draft.global.machines.find((m) => m.id === draft.params.focusedMachineId)
      ?.type === "Additive"
      ? "Process"
      : "Job";
  const machineAnalysisTabFromStorage = getMachineAnalysisPageTab();
  draft.userViewPreference.machineAnalysisView =
    !defaultTab && machineAnalysisTabFromStorage
      ? machineAnalysisTabFromStorage
      : defaultTab
        ? tabName
        : draft.userViewPreference.machineAnalysisView;
  // previous selected tab in machine analysis page should be selected when user visit ipc page and return to machine analysis page
  setMachineAnalysisPageTab(draft.userViewPreference.machineAnalysisView);
}

// mutates state so pass proxy
function toggleMeasurementDetail(
  nextState: RootState,
  currentState: Readonly<RootState>,
  id: string
) {
  const details = nextState.focusedMachine.measurementDetails[id];
  if (details) {
    details.active = !details.active;
    if (
      !details.active &&
      nextState.focusedMachine.mainMeasurementDetail === id
    ) {
      // we do not want a non-active measurement to be the main one.
      nextState.focusedMachine.mainMeasurementDetail = undefined;
    } else if (
      details.active &&
      selectActiveMeasurementTypeIds(currentState).length === 0
    ) {
      // we want the first active measurement to be the main one by default.
      nextState.focusedMachine.mainMeasurementDetail = id;
    }
  }
  const { seriesColours } = nextState.visualisations.machineAnalysis;
  if (!seriesColours[id]) {
    seriesColours[id] = getContrastingColour(Object.keys(seriesColours).length);
  }
}
